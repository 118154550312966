import { Card, CardContent, CardMedia, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Markdown from '../../../app/components/Markdown';
import GlowingButton from '../GlowingButton';

interface Props {
  icon: string;
  title: string;
  paragraph: string;
  redirectUrl: string;
}

const useStyles = makeStyles(theme => ({
  conatiner: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    flexDirection: 'column',
    width: `calc(50% - ${ theme.spacing(4) }px)`,
    textAlign: 'center',

    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  title: {
    margin: theme.spacing(2, 4),
    fontWeight: 600,
    fontSize: '28px',
  },
  card: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3, 2, 4),
  },
  image: {
    objectFit: 'contain',
  },
  content: {
    lineHeight: '2em',
    padding: theme.spacing(0),
  },
}));

export default function SwapboxCard({ icon, title, paragraph, redirectUrl }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={ classes.conatiner }>
      <Card className={ classes.card }>
        <Typography variant="h3" className={ classes.title } >{ title }</Typography>
        <CardMedia className={ classes.image } component="img" alt="" height="200" image={ icon } />
        <CardContent>
          <Markdown content={ paragraph } className={ classes.content } />
        </CardContent>
        <GlowingButton path={ redirectUrl }>{ t('home.button_subscribe') }</GlowingButton>
      </Card>
    </div>
  );
}
