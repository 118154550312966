import { addDoc, collection, deleteDoc, doc, setDoc, updateDoc } from '@firebase/firestore';
import { SnackbarKey, useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useFirestore, useUser } from 'reactfire';

import { MySwapDraft, Profile } from '../types';
import useDocRef from './useDocRef';

function useProfileRef() {
  const db = useFirestore();
  const { data: user } = useUser();
  return useDocRef<Profile>(db, 'profiles', user?.uid || '');
}

export function useUpdateProfile() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const profileRef = useProfileRef();

  const updateProfile = useCallback(async (profile: Partial<Profile>, quiet = false) => {
    if (!profileRef) return;

    let key: SnackbarKey | undefined = undefined;
    if (quiet) key = enqueueSnackbar(t('profile.updating_profile_message'), { variant: 'info' });

    try {
      await updateDoc(profileRef, profile);
      if (quiet) {
        if (key) closeSnackbar(key);
        enqueueSnackbar(t('profile.update_successful_message'), { variant: 'success' });
      }
    }
    catch (error) {
      if (quiet) {
        if (key) closeSnackbar(key);
        enqueueSnackbar(t('profile.update_failed_message'), { variant: 'error' });
      }
    }
  }, [profileRef]);

  return updateProfile;
}

export function useAddEditMySwap() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const swapboxRef = collection(useProfileRef(), 'swapbox');
  const history = useHistory();

  const addEditMySwap = useCallback(async (swap: MySwapDraft) => {
    if (!swapboxRef || !swap) return;

    const key = enqueueSnackbar(t('profile.addedit_swap_message'), { variant: 'info' });

    try {
      if (!swap.id) {
        const ref = await addDoc(swapboxRef, swap);
        await updateDoc(ref, { id: ref.id });
        closeSnackbar(key);
        enqueueSnackbar(t('profile.add_swap_message_success'), { variant: 'success' });
        history.push('/swapbox');
      }
      else {
        await setDoc(doc(swapboxRef, swap.id), swap);
        closeSnackbar(key);
        enqueueSnackbar(t('profile.edit_swap_message_success'), { variant: 'success' });
        history.push('/swapbox');
      }
    }
    catch (error) {
      console.log(error);
      closeSnackbar(key);
      enqueueSnackbar(t('profile.addedit_swap_message_failed'), { variant: 'error' });
    }
  }, [swapboxRef]);

  const deleteMySwap = useCallback(async (id: string) => {
    if (!swapboxRef || !id) return;

    try {
      await deleteDoc(doc(swapboxRef, id));
      enqueueSnackbar(t('profile.delete_swap_message_success'), { variant: 'success' });

    }
    catch (error) {
      console.log(error);
      enqueueSnackbar(t('profile.delete_swap_message_failed'), { variant: 'error' });
    }
  }, [swapboxRef]);

  return { addEditMySwap, deleteMySwap };
}
