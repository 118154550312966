import { AppBar, Box, Button, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import logo from '../../../assets/logo.png';
import ToolbarActions from './ToolbarActions';

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: 'linear-gradient(to bottom, hsla(0,0%,94%,1) 60%, hsla(0,0%,94%,.8))',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: 'hidden',
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  myswaps: {
    color: theme.palette.secondary.main,
  },
  menuButton: {
    marginRight: 36,
  },
  logoButtonWrapper: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    padding: 0,
    background: theme.palette.background.paper,
    boxShadow: '0px 0px 80px 80px ' + theme.palette.background.paper,
  },
  logo: {
    display: 'block',
    height: '2.6em',
  },
}));

function Navbar() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <AppBar position="fixed" className={ classes.appBar }>
      <Toolbar className={ classes.toolbar }>
        <div />
        <Box className={ classes.logoButtonWrapper }>
          <Button color="inherit" onClick={ () => history.push('/') }>
            <Typography variant="h6" color="inherit" noWrap>
              <img src={ logo } alt="mySwaps" className={ classes.logo } />
            </Typography>
          </Button>
        </Box>
        <ToolbarActions />
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
