import { Box, Card, CardContent, CardMedia, makeStyles, Typography } from '@material-ui/core';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { HomeData } from '..';

type Props = PropsWithChildren<HomeData['steps']['steps'][0] & { index: number }>;

const useStyles = makeStyles(theme => ({
  step: {
    margin: theme.spacing(2, 1),
    padding: theme.spacing(4, 0, 0),
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    maxWidth: '300px',

    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      maxWidth: 'initial',
      margin: theme.spacing(2, 1),
      padding: theme.spacing(0),
    },
  },
  title: {
    margin: theme.spacing(2, 0),
    textAlign: 'center',
  },
  card: {
    flex: '1 1 auto',
    borderRadius: theme.spacing(2),
    position: 'relative',
  },
  image: {
    objectFit: 'contain',
    // background: theme.palette.secondary.main,
    padding: theme.spacing(2, 0),
  },
  overlay: {
    position: 'absolute',
    top: 0,
    height: 200,
    width: '100%',
  },
}));

export default function Step({ index, icon, title, paragraph, children }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={ classes.step }>
      <Typography variant="h3" className={ classes.title } color="primary">{ t('home.steptitle') } { index }</Typography>
      <Card className={ classes.card }>
        <CardMedia className={ classes.image } component="img" alt="" height="200" image={ icon } />
        { children &&
          <Box className={ classes.overlay }>
            { children }
          </Box>
        }
        <CardContent>
          <Typography gutterBottom variant="h4" align="center" component="div">{ title }</Typography>
          <Typography variant="body2" color="textSecondary">
            { paragraph }
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}
