import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  title: string;
}

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(3, 0),
    position: 'relative',
  },
  dashedBorder: {
    width: '100%',
    borderBottom: '1px dashed grey',
    borderBottomColor: theme.palette.grey[500],
  },
  title: {
    position: 'absolute',
    background: theme.palette.background.default,
    padding: theme.spacing(0, 2),
    color: theme.palette.grey[500],
    fontSize: '.8rem',
  },
}));
export default function LegendBorder({ title }: Props) {
  const classes = useStyles();

  return (
    <Box className={ classes.sectionTitle }>
      <div className={ classes.dashedBorder }></div>
      <Typography variant="subtitle1" className={ classes.title }>{ title }</Typography>
    </Box>
  );
}
