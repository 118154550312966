import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Edit } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useProfile } from '../../app/components/AuthProvider/hooks';
import GridItemCard from './GridItemCard';

const useStyles = makeStyles({
  listItem: {
    paddingLeft: 0,
  },
});

export default function SubscriptionStatus() {
  const profile = useProfile();
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  if (!profile?.waitingList && !profile?.giftWaitingList) return null;

  return (
    <GridItemCard title={ t('profile.waiting_list_status_title') }>
      <List>
        { profile?.waitingList &&
          <ListItem className={ classes.listItem }>
            <ListItemText primary={ t('profile.waiting_list_jewelry_box_message') } />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="edit" onClick={ () => history.push('/subscribe') }>
                <Edit />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        }
        { profile?.giftWaitingList &&
          <ListItem className={ classes.listItem }>
            <ListItemText primary={ t('profile.waiting_list_gift_box_message') } />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="edit" onClick={ () => history.push('/gift-subscribe') }>
                <Edit />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        }
      </List>
    </GridItemCard>
  );
}
