import { getAnalytics, logEvent } from 'firebase/analytics';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirebaseApp } from 'reactfire';

export default function usePageTitle(title: string, prefix = 'mySwaps - ') {
  const app = useFirebaseApp();
  const { t } = useTranslation();

  useEffect(() => {
    const pageTitle = `${t(prefix)}${t(title)}`;
    document.title = pageTitle;

    const analytics = getAnalytics(app);
    logEvent(analytics, 'page_view', { pageTitle });
  }, [title]);
}
