import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { memo, PropsWithChildren } from 'react';

interface Props {
  className?: string;
  size?: 'normal' | 'large' | 'small';
}

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: 'clamp(1.8rem, 5vw, 2.8rem)',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: '1rem',

    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  large: {
    fontSize: 'clamp(2.2rem, 7vw, 3.8rem)',
  },
  small: {
    fontSize: 'clamp(1.3rem, 4vw, 2.2rem)',
  },
}));

function PageHeadline({ children, className, size = 'normal' }: PropsWithChildren<Props>) {
  const classes = useStyles();

  return (
    <Typography variant="h2" className={ clsx(
      classes.root, className,
      {
        [classes.large]: size === 'large',
        [classes.small]: size === 'small',
      },
    ) }>
      { children }
    </Typography>
  );
}

export default memo(PageHeadline);
