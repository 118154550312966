import { collection } from '@firebase/firestore';
import { useFirestore, useFirestoreCollectionData, useUser } from 'reactfire';

import { MySwapDraft, Profile } from '../types';
import useDocRef from './useDocRef';

export default function useSwapbox() {
  const db = useFirestore();
  const { data: user } = useUser();
  const profileRef = useDocRef<Profile>(db, 'profiles', user?.uid || '');
  const { data, error, status } = useFirestoreCollectionData<MySwapDraft>(collection(profileRef, 'swapbox'), { idField: 'id' });

  return { swapbox: data, error, loading: status === 'loading' };
}
