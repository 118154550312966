import { DocumentReference, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';

// Fix useFirestoreDocData, because the original hook does not handle null / undefined refs correctly
export default function useAppFirestoreDocData<T = unknown>(
  ref: DocumentReference<T> | undefined,
) {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(!!ref);

  useEffect(() => {
    if (!ref) return setData(undefined);
    setLoading(true);

    const unsubscribe = onSnapshot(ref, (doc) => {
      if (doc.exists()) setData(doc.data());
      else setData(undefined);

      setLoading(false);
    });

    return unsubscribe;
  }, [ref]);

  return { data, loading };
}
