import useAppFirestoreDocData from '../../hooks/useAppFirestoreDocData';
import useLocaleDocRef from '../../hooks/useLocaleDocRef';

export interface FAQData {
  question: string;
  answer: string;
  showOnHome: boolean;
}

export default function useFAQEntry(id: string) {
  const ref = useLocaleDocRef<FAQData>(`faqs/${id}`);
  const { data, loading } = useAppFirestoreDocData(ref);
  return { faq: data, loading };
}
