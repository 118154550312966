import { Chip, makeStyles } from '@material-ui/core';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  selected: string[];
  i18nNamespace: string;
}

const useStyles = makeStyles(() => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

function ValueChips({ i18nNamespace, selected }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const getOptionText = useCallback(
    (key: string) => t(`${ i18nNamespace }.option_${ key.toLowerCase() }`),
    [i18nNamespace],
  );

  return (
    <div className={ classes.chips }>
      { selected.map(value => (
        <Chip key={ value } label={ getOptionText(value) } className={ classes.chip } />
      )) }
    </div>
  );
}

export default memo(ValueChips);
