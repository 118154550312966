import { memo } from 'react';
import MatCarousel from 'react-material-ui-carousel';

import useUrls from '../../hooks/useUrls';
import CarouselItem from './CarouselItem';

interface Props {
  images: string[];
  className?: string;
}

function Carousel({ images, className }: Props) {
  const urls = useUrls(images);

  return (
    <MatCarousel
      navButtonsAlwaysInvisible
      stopAutoPlayOnHover={ false }
      animation="fade"
      indicators={ false }
      interval={ 5000 }
      className={ className }
    >
      { urls?.map((url, index) => (<CarouselItem src={ url } key={ index } />)) }
    </MatCarousel>
  );
}

export default memo(Carousel);
