import { useEffect, useState } from 'react';

import box1 from '../../app/assets/box1.svg';
import box2 from '../../app/assets/box2.svg';
import cart from '../../app/assets/cart.png';
import heart from '../../app/assets/heart.png';
import logo from '../../app/assets/logo.png';
import questionMark from '../../app/assets/question-mark.png';
import swap from '../../app/assets/swap.png';
import useAppFirestoreDocData from '../../app/hooks/useAppFirestoreDocData';
import useLocaleDocRef from '../../app/hooks/useLocaleDocRef';
import { HomeData } from '.';

interface CMSData {
  subtitle: string;
  subtitle2: string;
  subscribeBoxContent: string;
  howItWorksTitle: string;
  howItWorksTitle1: string;
  howItWorksContent1: string;
  howItWorksImages1: string[];
  howItWorksEarrings1: string[];
  howItWorksRings1: string[];
  howItWorksTitle2: string;
  howItWorksContent2: string;
  howItWorksTitle3: string;
  howItWorksContent3: string;
  howItWorksTitle4: string;
  howItWorksContent4: string;
  wyg: { title: string, image: string }[];
  swapBoxTitle: string;
  swapBoxSubTitle: string;
  swapBoxBoxTitle1: string;
  swapBoxBoxContent1: string;
  swapBoxBoxTitle2: string;
  swapBoxBoxContent2: string;
  galleryTitle: string;
  gallery: string[];
  faqTitle: string;
}

export default function useHomepageData() {
  const ref = useLocaleDocRef<CMSData>('pages/home-page');
  const { data, loading } = useAppFirestoreDocData(ref);

  const [headerData, setHeaderData] = useState({
    logo: logo,
    subtitle1: '',
    subtitle2: '',
    subscribeBoxContent: '',
  });
  const [stepsData, setStepsData] = useState({
    title: '',
    steps: [
      { icon: questionMark, title: '', paragraph: '' },
      { icon: cart, title: '', paragraph: '' },
      { icon: heart, title: '', paragraph: '' },
      { icon: swap, title: '', paragraph: '' },
    ],
  });
  const [wygData, setWygData] = useState<{ title: string, wyg: { title: string, image: string }[] }>({
    title: '',
    wyg: [],
  });
  const [swapBoxData, setSwapBoxData] = useState({
    title: '',
    subtitle: '',
    swapBoxes: [
      { icon: box1, title: '', paragraph: '' },
      { icon: box2, title: '', paragraph: '' },
    ],
  });
  const [galleryData, setGalleryData] = useState<{ title: string, galleryImages: string[] }>({
    title: '',
    galleryImages: [],
  });

  useEffect(() => {
    if (loading || !data) return;

    // set header data
    const newHeaderData: HomeData['header'] = {
      logo: logo,
      subtitle1: data.subtitle,
      subtitle2: data.subtitle2,
      subscribeBoxContent: data.subscribeBoxContent,
    };
    setHeaderData(newHeaderData);

    // set steps data
    const steps = [];
    steps.push({
      icon: questionMark,
      title: data.howItWorksTitle1,
      paragraph: data.howItWorksContent1,
      images: data.howItWorksImages1,
      earrings: data.howItWorksEarrings1,
      rings: data.howItWorksRings1,
    });
    steps.push({
      icon: cart,
      title: data.howItWorksTitle2,
      paragraph: data.howItWorksContent2,
    });
    steps.push({
      icon: heart,
      title: data.howItWorksTitle3,
      paragraph: data.howItWorksContent3,
    });
    steps.push({
      icon: swap,
      title: data.howItWorksTitle4,
      paragraph: data.howItWorksContent4,
    });
    setStepsData({
      title: data.howItWorksTitle,
      steps: steps,
    });

    setWygData({
      title: 'What you get',
      wyg: data.wyg,
    });

    // set myswap data
    const swapBoxes = [];
    swapBoxes.push({
      icon: box1,
      title: data.swapBoxBoxTitle1,
      paragraph: data.swapBoxBoxContent1,
    });
    swapBoxes.push({
      icon: box2,
      title: data.swapBoxBoxTitle2,
      paragraph: data.swapBoxBoxContent2,
    });

    setSwapBoxData({
      title: data.swapBoxTitle,
      subtitle: data.swapBoxSubTitle,
      swapBoxes,
    });

    setGalleryData({
      title: data.galleryTitle,
      galleryImages: data?.gallery || [],
    });
  }, [data, loading]);

  return { headerData, stepsData, wygData, swapBoxData, loading, galleryData, faqTitle: data?.faqTitle };
}
