import { Box, Button, Container, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import headImage from '../assets/headimage.png';
import GiftsImageContainer from '../GiftsImageContainer';
import Headline from '../Headline';
import { PickCmsData } from '../useXmaspageData';
import WygCard from './WygCard';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(6, 0),
  },
  headImage: {
    maxWidth: '200px',
    minWidth: '50%',
    marginBottom: theme.spacing(6),
    marginLeft: 0,
    marginRight: 0,

    [theme.breakpoints.down(650)]: {
      display: 'none',
    },
  },
  cardsColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.between(420, 650)]: {
      flexDirection: 'row',
      width: 390,
      justifyContent: 'space-between',
    },
  },
  centerCardsColumn: {
    padding: theme.spacing(0, 4),

    [theme.breakpoints.down(650)]: {
      padding: 0,
    },
  },
  giftsImageContainer: {
    justifyContent: 'center',
    flexDirection: 'row',

    [theme.breakpoints.down(650)]: {
      flexDirection: 'column',
    },
  },
  imagesClassName: {
    [theme.breakpoints.down(950)]: {
      display: 'none',
    },
  },
}));

export default function WhatYouGet({ wygTitle, wygTiles = [] }: PickCmsData<'wygTitle' | 'wygTiles'>) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container maxWidth="lg" className={ classes.container }>
      <Headline title={ wygTitle } />

      <GiftsImageContainer className={ classes.giftsImageContainer } imagesClassName={ classes.imagesClassName } >
        <Box className={ classes.cardsColumn }>
          <WygCard tile={ wygTiles[0] } />
          <WygCard tile={ wygTiles[1] } />
        </Box>
        <Box className={ clsx(classes.cardsColumn, classes.centerCardsColumn) }>
          <WygCard tile={ wygTiles[2] } />
          <img src={ headImage } className={ classes.headImage } />
          <WygCard tile={ wygTiles[3] } />
        </Box>
        <Box className={ classes.cardsColumn }>
          <WygCard tile={ wygTiles[4] } />
          <WygCard tile={ wygTiles[5] } />
        </Box>
      </GiftsImageContainer>

      <Button variant="contained" color="primary">
        { t('xmas.sign_up_now') }
      </Button>
    </Container>
  );
}
