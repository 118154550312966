import { Button, CardActions, CardContent, Chip, makeStyles, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { Delete, Edit } from '@material-ui/icons';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Currency } from 'react-intl-number-format/dist/index.js';

import { useAddEditMySwap } from '../../app/hooks/useUpdateProfile';
import { MySwapDraft } from '../../app/types';
import AddEditDialog from '../AddEditSwapDialog';
import CardImage from './CardImage';
import DeleteConfirmation from './DeleteConfirmation';

const useStyles = makeStyles(theme => ({
  card: {
    alignSelf: 'stretch',
    marginBottom: theme.spacing(2),
    display: 'flex',
    boxShadow: 'inset 0px 0px 0px 1px rgb(0 0 0 / 12%)',
    borderWidth: 0,

    [theme.breakpoints.down(500)]: {
      flexDirection: 'column',
    },
  },
  image: {
    flex: '0 0 25%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
  },
  content: {
    flex: '1 0 auto',
  },
  actions: {
    justifyContent: 'flex-end',
  },
  headline: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  chip: {
    margin: 2,
  },
}));

export default function Swap(draft: MySwapDraft) {
  const {
    id, swap, jewelryType, jewelryCategories, gemstoneCut, gemstoneSetting,
    silverFinish, stoneSizes, earingSizes, pendantSizes, stoneTypes, symbol,
  } = draft;
  const styles = useStyles();
  const { t } = useTranslation();
  const getOptionText = useCallback((key?: string, i18nNamespace?: string) => t(`${ i18nNamespace }.option_${ key?.toLowerCase() }`), [t]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const { deleteMySwap } = useAddEditMySwap();
  const swapboxOptions = useMemo(() => ([
    { i18nNamespace: 'select_jewelry_categories', values: jewelryCategories },
    { i18nNamespace: 'select_gemstone_setting', values: gemstoneSetting },
    { i18nNamespace: 'select_earing_size', values: earingSizes },
    { i18nNamespace: 'select_stone_type', values: stoneTypes },
    { i18nNamespace: 'select_gemstone_cut', values: gemstoneCut },
    { i18nNamespace: 'select_stone_size', values: stoneSizes },
    { i18nNamespace: 'select_pendant_size', values: pendantSizes },
    { i18nNamespace: 'select_silver_finish', values: silverFinish },
    { i18nNamespace: 'select_symbol', values: symbol },
  ]), [draft]);
  const [openEdit, setOpenEdit] = useState(false);

  const handleClose = (agree: boolean) => {
    if (agree && id) {
      deleteMySwap(id);
    }
    setConfirmationOpen(false);
  };

  return (
    <>
      <Card variant="outlined" className={ styles.card }>
        <DeleteConfirmation open={ confirmationOpen } handleClose={ handleClose } />
        <CardImage
          className={ styles.image }
          image={ 'https://picsum.photos/300' }
          title="Swap image"
        />
        <div className={ styles.wrapper }>
          <CardContent className={ styles.content }>
            <div className={ styles.headline }>
              <Typography gutterBottom variant="h3">{ swap?.title }</Typography>
              <Typography gutterBottom variant="h3" component="span">
                <Currency as="span">{ swap?.price }</Currency>
              </Typography>
            </div>
            <Typography gutterBottom variant="subtitle1" color="secondary" component="p">
              { t(getOptionText(jewelryType, 'select_jewelry_types')) }
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              { swap?.description }
            </Typography>
            { swapboxOptions
              .map(({ i18nNamespace, values }, index) => {
                if (!values?.length) return;

                return (
                  <div key={ index }>
                    <Typography gutterBottom variant="subtitle2" color="secondary" component="p">
                      { t(`${i18nNamespace}.title`) }
                    </Typography>
                    { values.map(value => (
                      <Chip
                        key={ value }
                        label={ t(getOptionText(value, i18nNamespace)) }
                        size="small"
                        className={ styles.chip }/>
                    )) }
                  </div>
                );
              })
            }
          </CardContent>
          <CardActions className={ styles.actions }>
            <Button size="small" variant="outlined" onClick={ () => setConfirmationOpen(true) } startIcon={ <Delete /> }>
              { t('myswapbox.button_delete') }
            </Button>
            <Button size="small" color="secondary" onClick={ () => setOpenEdit(true) } variant="contained" startIcon={ <Edit /> }>
              { t('myswapbox.button_edit') }
            </Button>
          </CardActions>
        </div>
      </Card>
      <AddEditDialog swap={ openEdit ? draft : undefined } onClose={ () => setOpenEdit(false) } />
    </>
  );
}
