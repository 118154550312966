import { Box, Button, Container, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useAuthContext } from '../../app/components/AuthProvider/hooks';
import Markdown from '../../app/components/Markdown';
import usePageTitle from '../../app/hooks/usePageTitle';
import useSubscribeData from './useSubscribeData';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 65px - 50px)',

    [theme.breakpoints.down(900)]: {
      alignItems: 'start',
    },
  },
  outerContainer: {
    display: 'flex',

    [theme.breakpoints.down(900)]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    alignItems: 'center',
    justifyContent: 'space-between',

    [theme.breakpoints.down(900)]: {
      width: '100%',
    },
  },
  button: {
    display: 'block',
  },
  imageContainer: {
    width: '60%',

    '&> img': {
      width: '100%',
    },

    [theme.breakpoints.down(900)]: {
      width: '80%',
      paddingTop: '10%',
    },
  },
  markdown: {
    margin: 0,
  },
}));

export default function SubscribePage() {
  const styles = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [buttonText, setButtonText] = useState(t('manage_subsciption.login_to_subscribe_button'));
  const { isAuthenticated, updateProfile } = useAuthContext();
  usePageTitle('manage_subsciption.own_subscribe_page_title');

  const { content, imageUrl, subscribed, loading } = useSubscribeData('loading ...');

  const handleClick = () => {
    if (!isAuthenticated) return history.push('/login', { page: '/subscribe' });
    if (!updateProfile) return;
    if (subscribed) return updateProfile({ waitingList: false });
    updateProfile({ waitingList: true });
  };

  useEffect(() => {
    if (loading) return;
    if (!isAuthenticated) return setButtonText(t('manage_subsciption.login_to_subscribe_button'));
    if (subscribed) return setButtonText(t('manage_subsciption.unsubscribe_button'));
    setButtonText(t('manage_subsciption.subscibe_button'));
  }, [isAuthenticated, subscribed, loading]);

  return (
    <Container>
      <div className={ styles.container }>
        <Box className={ styles.outerContainer }>
          <Box className={ styles.contentContainer }>
            <Markdown content={ content } className={ styles.markdown } />
            { !loading &&
          <Button
            className={ styles.button }
            variant={ subscribed ? 'outlined' : 'contained' }
            color="primary" onClick={ handleClick }>
            { buttonText }
          </Button>
            }
          </Box>
          <Box className={ styles.imageContainer }>
            <img src={ imageUrl } />
          </Box>
        </Box>
      </div>
    </Container>
  );

}
