import { Box, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Carousel from '../../../../app/components/Carousel';
import CrossFadeGallery from '../../../../app/components/CrossFadeGallery';
import { HomeData } from '../..';
import GlowingButton from '../../GlowingButton';
import headimage from './headimage.png';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
  },
  headline: {
    fontSize: '4rem',
    display: 'flex',
  },
  imageContainer: {
    position: 'relative',
    width: '40vw',
    minWidth: '25vw',
    maxHeight: '60vw',
    maxWidth: '300px',
  },
  image: {
    // position: 'absolute',
    // top: '-2rem',
    // right: '0',
    height: '100%',
    // maxHeight: '70vw',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',

    [theme.breakpoints.down(700)]: {
      marginLeft: theme.spacing(4),
      flexDirection: 'column-reverse',
    },
  },
  widget: {
    margin: theme.spacing(0, 2, 2),
    maxWidth: '400px',
    flex: '1 1 200%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imageInnerContainer: {
    display: 'inline-block',
    position: 'absolute',
    minWidth: '0',
    minHeight: '0',
    top: '-2rem',
    right: '0',
    height: '100%',
    maxHeight: '70vw',
  },
  earrings: {
    margin: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    width: '100%',
  },
  rings: {
    margin: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    width: '100%',
  },
  text: {
    margin: theme.spacing(0, 2, 2),
  },
  buttonContainer: {
    margin: theme.spacing(5, 0, 6),
  },
  button: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      boxShadow: '1px 1px 40px ' + theme.palette.primary.main,
    },
  },
}));

export default function Step1({ title, paragraph, images, earrings, rings }: HomeData['steps']['steps'][0]) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" position="relative">
      <Typography variant="h3" align="center" color="primary">{ t('home.steptitle') } 1</Typography>
      <Box display="flex" flexDirection="row" mt={ 2 }>
        <Box className={ classes.imageContainer }>
          <Box className={ classes.imageInnerContainer }>
            <CrossFadeGallery className={ classes.earrings } images={ earrings || [] } />
            <CrossFadeGallery className={ classes.rings } images={ rings || [] } />
            <img src={ headimage } alt="" className={ classes.image } />
          </Box>
        </Box>
        <Box className={ classes.content }>
          <Box className={ classes.widget }>
            <Carousel images={ images || [] } />
            <GlowingButton path="/subscribe">{ t('home.button_subscribe') }</GlowingButton>
          </Box>
          <Box flexDirection="column" className={ classes.text } flex="1 1 auto">
            <Typography variant="h4">{ title }</Typography>
            <Box mt={ 2 }>{ paragraph }</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
