import './i18n';

import i18next from 'i18next';
import { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl-number-format/dist/index.js';

import useCookieConsent from '../CookieConsentBar/useCookieConsent';
import LocaleConfigUpdater from './LocaleConfigUpdater';

interface I18NContextType {
  currentLang: string;
  currentLocale: string;
}

export const I18NContext = createContext<I18NContextType>({
  currentLang: 'de',
  currentLocale: 'de-DE',
});

const initialConfig = {
  locale: 'de-DE',
  options: {
    currency: 'EUR',
    maximumFractionDigits: 2,
  },
};

const localeMap: Record<string, string> = {
  'de': 'de-DE',
  'en': 'en-US',
  'de-DE': 'de-DE',
  'en-US': 'en-US',
};

export default function I18NProvider({ children }: PropsWithChildren<Record<never, never>>) {
  const [currentLang, setCurrentLang] = useState(i18next.language);
  const [currentLocale, setCurrentLocale] = useState(localeMap[currentLang]);
  const { cookieConsent } = useCookieConsent();

  const storeLang = useCallback((lng: string) => {
    if (cookieConsent) localStorage.setItem('i18nextLng', lng);
  }, [cookieConsent]);

  useEffect(() => {
    i18next.on('languageChanged', (lng) => {
      setCurrentLang(lng);
      setCurrentLocale(localeMap[lng]);
      storeLang(lng);
    });
  }, []);

  return (
    <I18NContext.Provider value={ { currentLang, currentLocale } }>
      <IntlProvider config={ initialConfig }>
        <LocaleConfigUpdater />
        { children }
      </IntlProvider>
    </I18NContext.Provider>
  );
}
