import { Box, Button, Container, Grid, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import xmas from '../assets/xmas.svg';
import Headline from '../Headline';
import { PickCmsData } from '../useXmaspageData';
import SpeechBubble from './SpeechBubble';

const useStyles = makeStyles(theme => ({
  xmasMainContainer: {
    margin: theme.spacing(6, 0),
  },
  title: {
    textAlign: 'left',
    padding: 0,
    margin: theme.spacing(3, 0, 1),
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    paddingRight: theme.spacing(2),
  },
  button: {
    padding: theme.spacing(1, 2),
    marginTop: theme.spacing(6),

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      boxShadow: `1px 1px 40px ${ theme.palette.primary.main }`,
    },
  },
  imageContainer: {
    position: 'relative',
    textAlign: 'center',

    '@media(max-width: 425px)': {
      marginTop: theme.spacing(6),
    },
  },
  image: {
    margin: theme.spacing(7, 2, 2),
    width: '80%',
    height: 'auto',
  },
  subHeading: {
    fontSize: '14px',
    letterSpacing: '4px',
    textTransform: 'uppercase',
  },
}));

export default function RegisterXmas({
  registerTitle, registerContent, registerSpeechBubble,
}: PickCmsData<'registerTitle' | 'registerContent' | 'registerSpeechBubble'>) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Container className={ classes.xmasMainContainer }>
      <Box display="flex" flexDirection="column">
        <Typography className={ classes.subHeading } variant="h6">{ t('xmas.coming_soon') }</Typography>
        <Grid container>
          <Grid item md={ 7 } sm={ 12 } xs={ 12 }>
            <Box className={ classes.content }>
              <Box flexDirection="column" flex="1 1 auto">
                <Headline title={ registerTitle } className={ classes.title } />
                <Box mt={ 2 }><Typography variant="body1">{ registerContent }</Typography></Box>
                <Button color="primary" variant="contained" className={ classes.button }>
                  { t('xmas.sign_up_now') }
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item md={ 5 } sm={ 12 } xs={ 12 }>
            <Box position="relative" className={ classes.imageContainer }>
              <img src={ xmas } alt="" className={ classes.image }/>
              <SpeechBubble>{ registerSpeechBubble }</SpeechBubble>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
