import { TextField, TextFieldProps } from '@material-ui/core';
import { FormikConfig, FormikValues, useFormik } from 'formik';

// this is just a helper for proper typing
class WrappedFormik<Values extends FormikValues = FormikValues> {
  formik(config: FormikConfig<Values>) {
    return useFormik<Values>(config);
  }
}

type Formik<V extends FormikValues = FormikValues> = ReturnType<WrappedFormik<V>['formik']>;

export default function FormikTextField<V extends FormikValues = FormikValues>(
  { formik, name, ...props }: TextFieldProps & { name: keyof V; formik: Formik<V> },
) {
  return <TextField
    name={ name }
    value={ formik.values[name] }
    onChange={ formik.handleChange }
    onBlur={ formik.handleBlur }
    error={ formik.touched[name] && !!formik.errors[name] }
    helperText={ formik.touched[name] && formik.errors[name] }
    { ...props }
  />;
}
