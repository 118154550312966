import { useContext } from 'react';

import { AuthContext } from '.';

export function useAuthContext() {
  const context = useContext(AuthContext);
  return context;
}

export function useProfile() {
  return useContext(AuthContext)?.profile;
}
