
import { Accordion, AccordionDetails, AccordionSummary, makeStyles, Typography, withStyles } from '@material-ui/core';
import { useMemo } from 'react';

import Markdown from '../Markdown';
import ExpandMoreIcon from './ExpandMoreIcon';
import useFAQEntry from './useFAQEntry';

interface Props {
  id: string;
  searchText: string;
  filterHomeEntries: boolean
}

const useStyles = makeStyles({
  heading: {
    fontSize: '18px',
    fontFamily: 'ProzaLibre',
  },
  content: {
    color: 'gray',
    fontSize: '14px',
    fontWeight: 300,
    margin: '0',
    padding: '0',
    maxWidth: 'none',
  },
});

const FaqAccordion = withStyles(theme => ({
  root: {
    margin: 0,
    border: 'none',
    boxShadow: 'none',
    borderTop: `1px solid ${ theme.palette.grey[300] }`,

    '&:first-child': {
      borderTop: 'none',
    },

    '&:before': {
      background: 'none',
    },
  },
  expanded: {
    margin: '0 !important', // hack due to bug in mui. expanded state cannot be overridden.
  },

}))(Accordion);

const FaqSummary = withStyles(theme => ({
  root: {
    minHeight: theme.spacing(8),
  },
  expanded: {
    marginTop: 'unset !important',
    marginBottom: 'unset !important',
  },
}))(AccordionSummary);

export default function FaqEntry({ id, searchText, filterHomeEntries }: Props) {
  const { faq, loading } = useFAQEntry(id);
  const classes = useStyles(loading);

  const searchSuccess = useMemo(() => {
    if (!faq) return false;
    const toSearch = [faq.answer || '', faq.question || ''];
    return toSearch
      .map(part => part.toLocaleLowerCase())
      .some(part => part.includes(searchText.toLocaleLowerCase()));
  }, [faq, searchText]);

  if (loading || !searchSuccess || !faq) return null;
  if (filterHomeEntries && !faq.showOnHome) return null;

  return (
    <FaqAccordion square>
      <FaqSummary expandIcon={ <ExpandMoreIcon /> } aria-controls={ `${id}-content` }>
        <Typography className={ classes.heading }>{ faq.question }</Typography>
      </FaqSummary>
      <AccordionDetails id={ `${id}-content` }>
        <Markdown className={ classes.content } content={ faq.answer }/>
      </AccordionDetails>
    </FaqAccordion>
  );
}
