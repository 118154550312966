import { Box, Container, makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FaqEntry from './FaqEntry';
import useFAQEntryIds from './useFAQEntryIds';

interface Props {
  showSearchBox?: boolean;
  filterHomeEntries?: boolean;
  faqTitle?: string;
}

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: '5em',
    marginTop: '3em',
  },
  title: {
    fontSize: '32px',
    fontFamily: 'ProzaLibre',
  },
  accordion: {
    borderRadius: theme.spacing(2),
    margin: theme.spacing(3, 0, 0),
    overflow: 'hidden',
    padding: theme.spacing(1, 3),
  },
}));

export default function FaqList({ faqTitle, showSearchBox = false, filterHomeEntries = false }: Props) {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string>('');
  const { faqIds, loading } = useFAQEntryIds();
  const classes = useStyles();

  return (
    <Container className={ classes.container } maxWidth="md">
      <Box component="div" m={ 1 } marginTop={ 5 } marginBottom={ 5 }>
        <Typography className={ classes.title } variant="h6" align="center" color="inherit">{ faqTitle || t('faq.title') }</Typography>
      </Box>
      { showSearchBox &&
        <Box component="div" m={ 1 }>
          <Typography variant="h6" align="center">
            <TextField fullWidth label={ t('faq.search') } onChange={ event => setSearchText(event.target.value) } />
          </Typography>
        </Box>
      }
      <Paper className={ classes.accordion }>
        { loading
          ? <Typography align="center" color="inherit">{ t('faq.loading') }</Typography>
          : faqIds.map((id, i) => (<FaqEntry key={ i } id={ id } searchText={ searchText } filterHomeEntries={ filterHomeEntries } />))
          || <Typography align="center" color="inherit">{ t('faq.nothing_found_message') }</Typography>
        }
      </Paper>
    </Container>
  );
}
