import { getDoc, query } from 'firebase/firestore';
import { useContext, useState } from 'react';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { useAsyncEffect } from 'use-async-effect';

import { I18NContext } from '../components/I18NProvider';
import useCollectionRef from '../hooks/useCollectionRef';
import { docRef } from '../hooks/useDocRef';
import { Swap } from '../types';

const emptySwaps: Swap[] = [];

export default function useSwaps() {
  const db = useFirestore();
  const { currentLocale } = useContext(I18NContext);
  const [swaps, setSwaps] = useState(emptySwaps);

  const swapsCollection = useCollectionRef<{ id: string }>(db, 'swaps');
  const swapsQuery = query(swapsCollection);
  const { data } = useFirestoreCollectionData(swapsQuery, { idField: 'id' });

  useAsyncEffect(async (isMounted) => {
    const swapRefs = await Promise.all(data?.map(({ id }) => {
      const ref = docRef<Swap>(db, `swaps/${ id }/locales/${ currentLocale }`);
      return getDoc(ref);
    }) || []);

    const swaps = swapRefs
      .map(ref => ref.data())
      .filter(swap => !!swap) as Swap[];

    if (isMounted()) setSwaps(swaps);
  }, [data, currentLocale]);

  return { swaps };
}
