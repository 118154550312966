import { useEffect } from 'react';
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';

export type ProtectedRouteProps = {
  isAuthorized?: boolean;
} & RouteProps;

export default function ProtectedRoute({ isAuthorized = true, ...routeProps }: ProtectedRouteProps) {
  const history = useHistory();

  useEffect(() => {
    if (isAuthorized) return;
    if (routeProps.path) {
      history.push('/login', { page: routeProps.path });
    }
  }, [routeProps.path]);

  if (!isAuthorized) {
    if (routeProps.path) return <></>;
    return <Redirect to="/login" />;
  }

  return <Route { ...routeProps } />;
}
