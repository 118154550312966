import { Box, makeStyles, Typography, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { LoginFormPages } from '.';
import GoogleSignIn from './GoogleSignIn';
import LegendBorder from './LegendBorder';
import RoundedButton from './RoundedButton';
import { LoginPageData } from './useLoginData';

interface Props {
  content: LoginPageData['loginContent'];
  onSectionChange: (page: LoginFormPages) => void;
}

const useStyles = makeStyles(theme => ({
  title: {

    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  recommendationText: {
    textAlign: 'center',
    fontSize: '.7rem',
    marginBottom: theme.spacing(2),
  },
}));

const ButtonContainer = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
})(Box);

export default function SignInContent({ content, onSectionChange }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Typography variant="h4" className={ classes.title }>{ content.loginTitle }</Typography>
      <LegendBorder title={ t('login.label_instantLogin') } />
      <Typography className={ classes.recommendationText } variant="subtitle1">{ content.instantLoginMsg }</Typography>
      <ButtonContainer mb={ 3 }><GoogleSignIn /></ButtonContainer>

      <LegendBorder title={ t('login.button_signUpWithEmail_legend') } />
      <ButtonContainer>
        <RoundedButton
          variant="outlined"
          color="primary"
          onClick={ () => onSectionChange('register') }
        >
          { t('login.button_signUpWithEmail') }
        </RoundedButton>
      </ButtonContainer>

      <ButtonContainer mt={ 2 } mb={ 1 }>
        <Typography variant="h6">{ content.alreadyHaveAccountTitle }</Typography>
      </ButtonContainer>
      <ButtonContainer mb={ 6 }>
        <RoundedButton
          variant="outlined"
          color="primary"
          onClick={ () => onSectionChange('login') }
        >
          { t('login.label_signInWithEmail') }
        </RoundedButton>
      </ButtonContainer>
    </>
  );
}
