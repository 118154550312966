import { DocumentData, FirestoreDataConverter, PartialWithFieldValue, QueryDocumentSnapshot } from 'firebase/firestore';
import { useMemo } from 'react';

export default function useConverter<T>(): FirestoreDataConverter<T> {
  const converter = useMemo(() => ({
    toFirestore(data: PartialWithFieldValue<T>) {
      return data as DocumentData;
    },
    fromFirestore(snap: QueryDocumentSnapshot<DocumentData>) {
      return snap.data() as T;
    },
  }), []);

  return converter;
}

export function createConverter<T>() {
  return {
    toFirestore(data: PartialWithFieldValue<T>) {
      return data as DocumentData;
    },
    fromFirestore(snap: QueryDocumentSnapshot<DocumentData>) {
      return snap.data() as T;
    },
  };
}
