import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useAsyncEffect } from 'use-async-effect';

import useUrls from '../../hooks/useUrls';
import { wait } from '../../utils';

interface Params {
  duration?: number;
  interval?: number;
  images: string[];
  className: string;
}

const useStyles = makeStyles<Theme, { duration: number, delay?: number }>({
  root: {
    position: 'relative',
  },
  bottomImg: {
    width: '100%',
    height: 'auto',
  },
  topImg: {
    width: '100%',
    height: 'auto',
    position: 'absolute',
    transition: ({ duration, delay = 0 }) => `opacity ${ duration / 1000 }s ease-in-out ${ delay }s`,
    top: 0,
    left: 0,
  },
});

function getSrc(images: string[], current: number) {
  return images[(images.length + current) % images.length];
}

export default function CrossFadeGallery({ interval = 5000, duration = 2000, images, className }: Params) {
  const urls = useUrls(images);
  const classes = useStyles({ duration: Math.min(duration - 100, interval) });
  const [current, setCurrent] = useState(0);
  const [fadeOutOpacity, setFadeOutOpacity] = useState(1);
  const [fadeInOpacity, setFadeInOpacity] = useState(0);

  const [fadeOutSrc, setFadeOutSrc] = useState<string>();
  const [fadeInSrc, setFadeInSrc] = useState<string>();

  useEffect(() => {
    const int = setInterval(() => {
      setCurrent(current => ((current + 1) % (urls.length || 1)));
    }, interval);

    return () => clearInterval(int);
  }, [urls, interval]);

  useAsyncEffect(async (isMounted) => {
    setFadeOutSrc(undefined);
    setFadeOutOpacity(1);

    await wait(0);

    if (!isMounted()) return;
    setFadeOutSrc(getSrc(urls, current - 1));

    await wait(0);

    if (!isMounted()) return;
    setFadeInSrc(undefined);
    setFadeInOpacity(0);

    await wait(0);

    if (!isMounted()) return;
    setFadeInSrc(getSrc(urls, current));

    // wait for rerenderings to be finished before fading
    await wait(100);

    if (!isMounted()) return;
    setFadeOutOpacity(0);
    setFadeInOpacity(1);
  }, [urls, current]);

  if (!urls) return null;

  return (
    <div className={ clsx(classes.root, className) }>
      { fadeOutSrc && <img src={ fadeOutSrc } style={ { opacity: fadeOutOpacity } } alt="" className={ classes.topImg }/> }
      { fadeInSrc && <img src={ fadeInSrc } style={ { opacity: fadeInOpacity } } alt="" className={ classes.topImg }/> }
    </div>
  );
}
