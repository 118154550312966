import useAppFirestoreDocData from '../../app/hooks/useAppFirestoreDocData';
import useLocaleDocRef from '../../app/hooks/useLocaleDocRef';

export interface CMSData {
  title: string;
  subtitle: string;
  wygTitle: string;
  wygTiles: WygTile[];
  registerTitle: string;
  registerContent: string;
  registerSpeechBubble: string
  image: string;
}

export interface WygTile {
  title: string;
  image: string;
}

export type PickCmsData<K extends keyof CMSData> = Partial<Pick<CMSData, K>>;

export default function useXmaspageData() {
  const localeRef = useLocaleDocRef<CMSData>('pages/xmas-page');
  const { data, loading } = useAppFirestoreDocData(localeRef);

  return { loading, ...(data || {}) };
}
