import { Box, Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'reactfire';

import RoundedButton from './RoundedButton';
interface Props {
  title: string;
  cancel: () => void;
}

export default function PasswordLogin({ title, cancel }: Props) {
  const auth = useAuth();

  const [touchedFields, setTouchedFields] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPasswordField, setShowPasswordField] = useState(false);
  const { t } = useTranslation();

  const handleLogin = async (event: FormEvent) => {
    event.preventDefault();
    if (showPasswordField) {
      setErrorMsg('');
      setLoading(true);
      try {
        await signInWithEmailAndPassword(auth, email, password);
      }
      catch (error) {
        setErrorMsg(t('login.message_invalidEmailOrPassword'));
      }
      setLoading(false);
    }
    else {
      setShowPasswordField(true);
    }
  };

  const touch = (field: string) => setTouchedFields(Array.from(new Set([...touchedFields, field])));
  const isPasswordInvalid = () => password === '' && touchedFields.includes('password');
  const isEmailInvalid = () => email === '' && touchedFields.includes('email');

  return (
    <form onSubmit={ handleLogin }>
      { errorMsg !== '' &&
        <Box mb={ 2 }><MuiAlert elevation={ 6 } variant="filled" severity="error">{ errorMsg }</MuiAlert></Box>
      }
      <Typography variant="h4">{ title }</Typography>
      <Box my={ 2 }>
        <TextField
          name="email"
          fullWidth
          label={ t('login.label_email') }
          value={ email }
          onChange={ event => setEmail(event.target.value) }
          onBlur={ () => touch('email') }
          error={ isEmailInvalid() }
          helperText={ isEmailInvalid() ? t('login.message_emailRequired') : '' }
        />
      </Box>
      <Box my={ 2 }>
        <TextField
          name="password"
          type="password"
          fullWidth
          label={ t('login.label_password') }
          value={ password }
          onChange={ event => setPassword(event.target.value) }
          onBlur={ () => touch('password') }
          error={ isPasswordInvalid() }
          helperText={ isPasswordInvalid() ? t('login.message_passwordRequired') : '' }
        />
        <Box mt={ 1 }>
          <Typography variant="caption" gutterBottom>{ t('login.label_forgotPassword') }</Typography>
        </Box>
      </Box>
      <Box my={ 2 } display="flex" justifyContent="space-between">
        <Button variant="text" onClick={ () => cancel() }>{ t('login.button_cancel') }</Button>
        <RoundedButton
          variant="contained"
          color="primary"
          type="submit"
          disabled={ touchedFields.length < 2 || isEmailInvalid() || isPasswordInvalid() || loading }
        >
          { t('login.button_signIn') }
          { loading && <CircularProgress color="inherit" size={ 16 } /> }
        </RoundedButton>
      </Box>
    </form>
  );
}
