import { Box, Container, makeStyles } from '@material-ui/core';
import { memo } from 'react';

import Markdown from '../../../app/components/Markdown';
import PageHeadline from '../../../app/components/PageHeadline';
import PageSubHeadline from '../../../app/components/PageSubHeadline';

interface Props {
  title: string;
  subTitle: string;
  content: string;
  swapboxImage?: string;
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('xs')]: {
      alignItems: 'start',
    },
  },
  inner: {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    paddingTop: theme.spacing(5),

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      alignItems: 'center',
      paddingTop: 0,
    },
  },
  markdown: {
    margin: 0,
    padding: 0,
  },
  imageContainer: {
    width: '45%',

    '& > img': {
      width: '100%',
    },

    [theme.breakpoints.down('xs')]: {
      width: '80%',
    },
  },
}));

function Header({ title, subTitle, content, swapboxImage }: Props) {
  const styles = useStyles();

  return (
    <Container className={ styles.container }>
      <Box className={ styles.inner }>
        <Box className={ styles.contentContainer }>
          <PageHeadline size="large">{ title }</PageHeadline>
          <PageSubHeadline>{ subTitle }</PageSubHeadline>
          <Markdown className={ styles.markdown } content={ content } />
        </Box>
        <Box className={ styles.imageContainer }>
          <img src={ swapboxImage } />
        </Box>
      </Box>
    </Container>
  );
}

export default memo(Header);
