import { useEffect, useState } from 'react';

import { useAuthContext } from '../../app/components/AuthProvider/hooks';
import useAppFirestoreDocData from '../../app/hooks/useAppFirestoreDocData';
import useLocaleDocRef from '../../app/hooks/useLocaleDocRef';
import useUrl from '../../app/hooks/useUrl';

interface CmsData {
  loggedOut: string;
  loggedOutImage: string;
  loggedInSubscribed: string;
  loggedInSubscribedImage: string;
  loggedInNotSubscribed: string;
  loggedInNotSubscribedImage: string;
}

export default function useSubscribeData(defaultContent: string) {
  const { isAuthenticated, profile, loading: authLoading } = useAuthContext();

  const ref = useLocaleDocRef<CmsData>('pages/subscribe-page');
  const { data, loading: dataLoading } = useAppFirestoreDocData(ref);

  const [content, setContent] = useState<string>(defaultContent);
  const [image, setImage] = useState<string>('');
  const [subscribed, setSubscribed] = useState(false);
  const [loading, setLoading] = useState(true);
  const imageUrl = useUrl(image);

  useEffect(() => {
    if (authLoading || dataLoading || !data) return;
    setSubscribed(!!profile?.waitingList);
    setLoading(false);

    if (!isAuthenticated) {
      setContent(data.loggedOut);
      setImage(data.loggedOutImage);
      return;
    }
    if (subscribed) {
      setContent(data.loggedInSubscribed);
      setImage(data.loggedInSubscribedImage);
      return;
    }
    setContent(data.loggedInNotSubscribed);
    setImage(data.loggedInNotSubscribedImage);
  }, [data, dataLoading, isAuthenticated, subscribed, profile]);

  return { content, imageUrl, subscribed, loading };
}
