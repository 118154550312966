import { getDownloadURL, ref } from 'firebase/storage';
import { useState } from 'react';
import { useStorage } from 'reactfire';
import { useAsyncEffect } from 'use-async-effect';

import { preloadImage } from '../utils';

export default function useUrls(images: string[]) {
  const storage = useStorage();
  const [urls, setUrls] = useState<string[]>([]);

  useAsyncEffect(async (isMounted) => {
    if (!images) return;
    const urls = await Promise.all(images.map(async (image) => {
      if (!image) return '';

      const url: string = await getDownloadURL(ref(storage, image));
      preloadImage(url);
      return url;
    }));

    if (isMounted()) setUrls(urls);
  }, [images]);

  return urls;
}
