import { Box, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

interface Props {
  title?: string;
  className?: string;
}

const useStyles = makeStyles(() => ({
  headline: {
    fontSize: 'clamp(2rem, 3.5vw, 2.5rem)',
    lineHeight: '1.2em',
    textAlign: 'center',
    maxWidth: '720px',
    padding: 0,
  },
}));

export default function Headline({ title, className }: Props) {
  const classes = useStyles();

  return (
    <Box mb={ 2 }>
      <Typography className={ clsx(classes.headline, className) } variant="h2" color="primary">
        { title }
      </Typography>
    </Box>
  );
}
