
export function wait(ms = 1000) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const preloaded: Record<string, boolean> = {};

export function preloadImage(path: string) {
  return new Promise<void>((resolve, reject) => {
    if (preloaded[path]) return resolve();
    preloaded[path] = true;
    const preImg = new Image();
    preImg.onload = () => resolve();
    preImg.onerror = reject;
    preImg.src = path;
  });
}

export function enumKeys<E>(e: E): (keyof E)[] {
  return Object.keys(e) as (keyof E)[];
}

export const IS_PRODUCTION = process.env.REACT_APP_ENVIRONMENT === 'production';
