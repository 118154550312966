import { doc, Firestore } from 'firebase/firestore';
import { useMemo } from 'react';

import useConverter, { createConverter } from './useConverter';

export default function useDocRef<T>(firestore: Firestore, path: string, ...pathSegments: string[]) {
  const converter = useConverter<T>();
  const ref = useMemo(
    () => doc(firestore, path, ...pathSegments).withConverter(converter),
    [firestore, path, pathSegments],
  );
  return ref;
}

// use this when hook not possible
export function docRef<T>(firestore: Firestore, path: string, ...pathSegments: string[]) {
  return doc(firestore, path, ...pathSegments).withConverter(createConverter<T>());
}
