import { Box, Container, makeStyles } from '@material-ui/core';

import { HomeData } from '..';
import Headline from '../Headline';
import WYGCard from './WYGCard';

const useStyles = makeStyles(theme => ({
  outer: {
    padding: theme.spacing(0, 6),
    position: 'relative',
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 2),
    },
    [theme.breakpoints.down(600)]: {
      padding: theme.spacing(3, 2),
    },
  },
  container: {
    zIndex: 1,
    position: 'relative',
  },
  imageContainer: {
    display: 'inline-block',
    position: 'absolute',
    top: theme.spacing(10),
    bottom: theme.spacing(3),
  },
  image: {
    height: '100%',
  },
}));

export default function WhatYouGet({ title, wyg }: HomeData['wyg']) {
  const classes = useStyles();

  return (
    <div className={ classes.outer }>
      <Container maxWidth="sm" className={ classes.container }>
        <Headline title={ title }/>

        <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="space-between">
          {
            wyg?.map((box, i) => (<WYGCard key={ i } { ...box } />))
          }
        </Box>
      </Container>
    </div>
  );
}
