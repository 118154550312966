
import { Checkbox, FormControl, Input, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { enumKeys } from '../../utils';
import ValueChips from './ValueChips';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '90%',
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Props<T extends string, TEnumValue> {
  i18nNamespace: string;
  value: string[];
  options: { [key in T]: TEnumValue };
  onChange: (value: string[]) => void;
}

export default function MultiSelectBox<T extends string, TEnumValue extends string>(
  { i18nNamespace, value, options, onChange }: Props<T, TEnumValue>,
) {
  const classes = useStyles();
  const { t } = useTranslation();
  const getOptionText = (key: string) => t(`${i18nNamespace}.option_${ key.toLowerCase() }`);
  const optionKeys = enumKeys(options);
  const isAllSelected = optionKeys.length > 0 && value.length === optionKeys.length;

  const handleChange = (values: string[]) => {
    if (values.includes('all')) {
      onChange(isAllSelected ? [] : optionKeys);
    }
    else {
      onChange(values);
    }
  };

  return (
    <FormControl className={ classes.formControl }>
      <InputLabel>{ t(`${i18nNamespace}.title`) }</InputLabel>
      <Select
        multiple
        value={ value }
        onChange={ (event) => handleChange(event.target.value as string[]) }
        input={ <Input /> }
        renderValue={ selected => (<ValueChips selected={ selected as string[] } i18nNamespace={ i18nNamespace } />) }
        MenuProps={ MenuProps }>
        <MenuItem
          value="all"
          classes={ {
            root: isAllSelected ? classes.selectedAll : '',
          } }
        >
          <Checkbox checked={ isAllSelected } />
          <ListItemText
            classes={ { primary: classes.selectAllText } }
            primary={ t('general.multiselect_select_all') }
          />
        </MenuItem>
        { optionKeys.map((key: string) => (
          <MenuItem key={ key } value={ key }>
            <Checkbox checked={ value.includes(key) } />
            <ListItemText primary={ getOptionText(key) } />
          </MenuItem>
        )) }
      </Select>
    </FormControl>
  );
}
