import { Slide, SlideProps, Zoom, ZoomProps } from '@material-ui/core';
import { forwardRef } from 'react';

// HACK: Missing forward ref in material UI implementation added: https://stackoverflow.com/a/67961603/2258093
// Can be removed with material UI 5
// eslint-disable-next-line react/display-name
// const DialogTransition = forwardRef((props: FadeProps, ref) => <Fade ref={ ref } { ...props } />);

export const DialogTransition = forwardRef(function transition(props: ZoomProps, ref) {
  return <Zoom ref={ ref } { ...props } />;
});

export const FsDIalogTransition = forwardRef(function transition(props: SlideProps, ref) {
  return <Slide direction="left" ref={ ref } { ...props } />;
});
