import { makeStyles } from '@material-ui/core';

import { HomeData } from '../..';
import Step from '../Step';
import image from './earring.png';
import icon from './step2.png';

const useStyles = makeStyles({
  image: {
    position: 'absolute',
    bottom: '-13px',
    left: '35%',
  },
});

export default function Step2(step: HomeData['steps']['steps'][0]) {
  const classes = useStyles();

  return (
    <Step index={ 2 } { ...step } icon={ icon }>
      <img src={ image } alt="" className={ classes.image } />
    </Step>
  );
}
