import { Box, CircularProgress, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useAuthContext } from '../../app/components/AuthProvider/hooks';
import usePageTitle from '../../app/hooks/usePageTitle';
import ManageAddresses from './ManageAddresses';
import PersonalInformation from './PersonalInformation';
import ProductPreferencesConfig from './ProductPreferencesConfig';
import SubscriptionStatus from './SubscriptionStatus';

const useStyles = makeStyles(theme => ({
  page: {
    margin: 0,
    padding: theme.spacing(3),
  },
  loading: {
    display: 'flex',
    height: '60vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function ProfilePage() {
  const classes = useStyles();
  const { profileLoading, profile } = useAuthContext();
  usePageTitle('profile.page_title');

  if (profileLoading) return (<Box className={ classes.loading }><CircularProgress /></Box>);
  if (!profile) return <></>;

  return (
    <Container>
      <Grid container direction="column" alignItems="stretch" className={ classes.page } >
        <PersonalInformation />
        <ManageAddresses />
        <SubscriptionStatus />
        { profile?.active && <ProductPreferencesConfig /> }
      </Grid>
    </Container>
  );
}
