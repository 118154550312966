import pick from 'lodash/pick';
import { createContext, PropsWithChildren, Suspense, useEffect, useState } from 'react';
import { useSigninCheck, useUser } from 'reactfire';

import { Profile, User } from '../../types';
import ProfileProvider from './ProfileProvider';

interface AuthContextType {
  authUser?: User | null;
  profile?: Profile | null;
  isAuthenticated: boolean;
  loading: boolean;
  profileLoading: boolean;
  updateProfile?: (profile: Partial<Profile>) => void;
}

export const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  loading: true,
  profileLoading: true,
});

export interface ProfileState {
  profile: Profile | null;
  loading: boolean;
  updateProfile?: (profile: Partial<Profile>) => void;
}

const initialProfile = { profile: null, loading: true };

export const AuthProvider = ({ children }: PropsWithChildren<Record<never, never>>) => {
  const [authUser, setAuthUser] = useState<User | null>();
  const [loading, setLoading] = useState(true);
  const [{ profile, loading: profileLoading, updateProfile }, setProfile] = useState<ProfileState>(initialProfile);
  const { status, data: signInCheckResult } = useSigninCheck({ });
  const signedIn = !!signInCheckResult?.signedIn;
  const { data: user } = useUser();

  useEffect(() => {
    if (status !== 'loading') setLoading(false);
    if (status !== 'loading' && (!signedIn || !user || user.isAnonymous)) setProfile(initialProfile);
    if (status === 'loading' || !signedIn || !user) return setAuthUser(null);
    setAuthUser(pick(user, 'uid', 'email', 'displayName', 'phoneNumber', 'photoURL'));
  }, [user, signedIn, status, setLoading, setAuthUser]);

  return (
    <>
      { !user?.isAnonymous && authUser?.uid &&
        <Suspense fallback={ <div>Loading profile...</div> }>
          <ProfileProvider uid={ authUser.uid } setProfile={ setProfile } />
        </Suspense>
      }
      <AuthContext.Provider value={ {
        authUser,
        profile,
        isAuthenticated: !!signedIn,
        loading,
        profileLoading,
        updateProfile,
      } }>
        { children }
      </AuthContext.Provider>
    </>
  );
};
