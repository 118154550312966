import { makeStyles } from '@material-ui/core';
import { memo, PropsWithChildren } from 'react';

const useStyles = makeStyles(theme => ({
  speechBubble: {
    fontSize: '12px',
    backgroundColor: theme.palette.primary.main,
    padding: '12px',
    borderRadius: '5px',
    position: 'absolute',
    top: '18%',
    right: '0',
    left: '50%',
    maxWidth: '270px',
    transform: 'translateY(-100%)',
    color: theme.palette.primary.contrastText,
    fontWeight: 500,
  },
  tail1:{
    position: 'absolute',
    bottom: '-19px',
    left: '2px',
    width: '0',
    height: '0',
    borderColor: '#c67600 transparent transparent transparent',
    borderWidth: '10px',
    borderStyle: 'solid',
  },
}));

function SpeechBubble({ children }: PropsWithChildren<Record<never, never>>) {
  const classes = useStyles();

  return (
    <div className={ classes.speechBubble }>
      <div>{ children }</div>
      <div className={ classes.tail1 }></div>
    </div>
  );
}

export default memo(SpeechBubble);
