import { useContext, useState } from 'react';
import { useAsyncEffect } from 'use-async-effect';

import { I18NContext } from '../app/components/I18NProvider';
import Markdown from '../app/components/Markdown';
import useAppFirestoreDocData from '../app/hooks/useAppFirestoreDocData';
import useLocaleDocRef from '../app/hooks/useLocaleDocRef';
import usePageTitle from '../app/hooks/usePageTitle';

interface Props {
  pageTitle: string;
  content: string;
  mode?: 'file' | 'cms';
}

export default function MarkdownPage({ pageTitle, content, mode = 'file' }: Props) {
  const [text, setText] = useState('');
  const { currentLocale } = useContext(I18NContext);
  const ref = useLocaleDocRef<{ content: string }>(mode === 'cms' ? `pages/${content}` : undefined);
  const { data } = useAppFirestoreDocData(ref);
  usePageTitle(pageTitle);

  useAsyncEffect(async (isMounted) => {
    if (mode === 'file') {
      const response = await fetch(content);
      const text = await response.text();
      if (isMounted()) setText(text);
    }
    else if (mode === 'cms') setText(data?.content || '');
    else setText(content);
  }, [content, mode, currentLocale, data]);

  return (<Markdown content={ text } />);
}
