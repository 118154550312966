import { Box, Card, CardActionArea, CardContent, IconButton, makeStyles, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAddresses from '../../../app/hooks/useAddresses';
import { Address } from '../../../app/types';
import AddressModal from './AddressModal';

interface Props {
  address: Address;
}

const useStyles = makeStyles(() => ({
  card: {
    position: 'relative',
    width: '100%',
  },
  deleteButton: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
}));

export default function AddressCard({ address }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [editOpen, setEditOpen] = useState(false);
  const { deleteAddress } = useAddresses();

  const handleRemove = () => {
    if (!address.id) return;
    deleteAddress(address.id);
  };

  return (
    <>
      <AddressModal open={ editOpen } address={ address } type={ address.type } onClose={ () => setEditOpen(false) } />
      <Card className={ classes.card }>
        <CardActionArea style={ { height: '100%' } } onClick={ () => setEditOpen(true) }>
          <CardContent>
            <Typography>{ t(`profile.${ address.type.toLowerCase() }`) }</Typography>
            <Box>{ address.receiver }</Box>
            <Box>{ address.address1 }</Box>
            { address.address2 && <Box>{ address.address2 }</Box> }
            <Box>{ address.zip } { address.city }</Box>
            <Box>{ address.country }</Box>
          </CardContent>
        </CardActionArea>
        <IconButton onClick={ handleRemove } className={ classes.deleteButton }><Delete /></IconButton>
      </Card>
    </>
  );
}
