import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { memo, PropsWithChildren } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: 'clamp(1.5rem, 4vw, 2.2rem)',
    marginBottom: '1rem',

    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
}));

function PageSubHeadline({ children, className }: PropsWithChildren<{ className?: string }>) {
  const classes = useStyles();

  return (
    <Typography variant="h3" className={ clsx(classes.root, className) }>
      { children }
    </Typography>
  );
}

export default memo(PageSubHeadline);
