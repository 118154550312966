import { makeStyles } from '@material-ui/core';
import { memo } from 'react';

interface Props {
  src: string;
}

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    width: '100%',
  },
  img: {
    width: '100%',
    height: 'auto',
    display: 'block',
  },
}));

function CarouselItem({ src }: Props) {
  const classes = useStyles();

  return (
    <div className={ classes.container }>
      <img src={ src } className={ classes.img } alt="" />
    </div>
  );
}

export default memo(CarouselItem);
