import { setDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useFirestore, useFirestoreDocData, useUser } from 'reactfire';
import { useAsyncEffect } from 'use-async-effect';

import useDocRef from '../../hooks/useDocRef';
import { useUpdateProfile } from '../../hooks/useUpdateProfile';
import { Profile } from '../../types';
import { ProfileState } from '.';

interface Props {
  uid: string;
  setProfile: (state: ProfileState) => void;
}

export default function ProfileProvider({ setProfile }: Props) {
  const db = useFirestore();
  const { data: user } = useUser();
  const [loading, setLoading] = useState(true);
  const profileRef = useDocRef<Profile>(db, 'profiles', user?.uid || '');
  const { status, data: profile, error, firstValuePromise } = useFirestoreDocData(profileRef, { idField: 'uid' });
  const updateProfile = useUpdateProfile();

  if (error) console.log(error);

  useAsyncEffect(async (isMounted) => {
    // make sure to realize, when there is no profile to load
    await firstValuePromise;
    if (!isMounted()) return;
    setLoading(false);
    return setProfile({ profile, loading: false, updateProfile });
  }, [firstValuePromise]);

  useEffect(() => {
    if (loading || status === 'loading') return;
    setLoading(false);
    if (!user) return setProfile({ profile: null, loading: false, updateProfile });

    // pass profile back to auth provider
    if (profile) {
      // update profile email address, since it's actually managed in the user
      if (user.email && user.email !== profile.email) updateProfile({ email: user.email }, true);
      return setProfile({ profile, loading: false, updateProfile });
    }

    // don't create profile for anonymous users
    if (user.isAnonymous) return setProfile({ profile: null, loading: false });

    const displayName = user.displayName || user.email || '';
    const { uid } = user;

    const newProfile: Profile = { displayName, uid, addressList: [] };
    if (user.phoneNumber) newProfile.phoneNumber = user.phoneNumber;
    if (user.photoURL) newProfile.photoURL = user.photoURL;
    if (user.email) newProfile.email = user.email;

    try {
      setDoc(profileRef, newProfile);
    }
    catch (error) {
      console.log(error);
    }
  }, [user, status, profile, setProfile, loading]);

  return null;
}
