import { Button, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Gavel, Info, LiveHelp, Lock } from '@material-ui/icons';
import i18next from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useHasAlphaAccess from '../../hooks/useHasAlphaAccess';
import LanguageSelectionDialog from '../LanguageSelectionDialog';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    // position: 'sticky',
    bottom: 0,
    left: 0,
    height: 50,
    width: '100%',
    backgroundColor: theme.palette.background.default,
    // this is the same shadow as at the toolbar just turned around to be at the top
    boxShadow: '0px -2px 4px -1px rgb(0 0 0 / 20%), 0px -4px 5px 0px rgb(0 0 0 / 14%), 0px -1px 10px 0px rgb(0 0 0 / 12%)',

    '& > button': {
      alignItems: 'flex-start',
      marginRight: theme.spacing(4),
    },
  },
  flag: {
    width: '24px',
  },
}));

export default function Footer() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const hasAlphaAccess = useHasAlphaAccess();

  return (
    <>
      <LanguageSelectionDialog dialogOpen={ dialogOpen } onClose={ () => setDialogOpen(false) }/>
      <div className={ classes.row }>
        <Button startIcon={ <LiveHelp/> } onClick={ () => history.push('/faq') }>{ t('menu.faq') }</Button>
        { hasAlphaAccess &&
          <Button startIcon={ <Gavel/> } onClick={ () => history.push('/terms') }>{ t('menu.terms') }</Button>
        }
        <Button startIcon={ <Lock/> } onClick={ () => history.push('/privacy') }>{ t('menu.privacy') }</Button>
        <Button startIcon={ <Info/> } onClick={ () => history.push('/about') }>{ t('menu.about') }</Button>
        <Button startIcon={
          <Icon><img className={ classes.flag } src={ `./images/${i18next.language}.png` } /></Icon>
        } onClick={ () => setDialogOpen(true) }>{ t('menu.language') }</Button>
      </div>
    </>
  );
}
