import { Box, Container, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageHeadline from '../../app/components/PageHeadline';
import usePageTitle from '../../app/hooks/usePageTitle';
import PasswordLogin from './PasswordLogin';
import PasswordRegister from './PasswordRegister';
import SignInContent from './SignInContent';
import useLoginData from './useLoginData';

const useStyles = makeStyles(theme => ({
  outerContainer: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    paddingTop: theme.spacing(4),

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  imageContainer: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& > img': {
      width: '100%',
    },

    [theme.breakpoints.down('xs')]: {
      width: '80%',
    },
  },
  title: {
    padding: theme.spacing(0, 3, 5),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    marginLeft: theme.spacing(6),

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: theme.spacing(5, 3, 0),
      marginLeft: 0,
    },
  },
}));

export type LoginFormPages = 'login' | 'register' | '';

export default function LoginPage() {
  const { loginContent, imageUrl, loading } = useLoginData();
  const [currentForm, setCurrentForm] = useState<LoginFormPages>('');
  const classes = useStyles();
  const { t } = useTranslation();
  usePageTitle('login.page_title');

  if (loading) return <div>loading...</div>;
  return (
    <Container>
      <Box className={ classes.outerContainer }>
        <Box className={ classes.imageContainer }>
          <PageHeadline size="normal">{ loginContent.title }</PageHeadline>
          <img src={ imageUrl } />
        </Box>
        <Box className={ classes.contentContainer }>
          { currentForm === '' &&
            <SignInContent content={ loginContent } onSectionChange={ setCurrentForm } />
          }
          { currentForm === 'login' &&
            <PasswordLogin title={ t('login.button_signUpWithEmail_legend') } cancel={ () => setCurrentForm('') }/>
          }
          { currentForm === 'register' &&
            <PasswordRegister title={ loginContent.createAccountTitle } cancel={ () => setCurrentForm('') } />
          }
        </Box>
      </Box>
    </Container>
  );
}
