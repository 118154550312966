import { useMemo } from 'react';

import useUrls from './useUrls';

export default function useUrl(image?: string) {
  const images = useMemo(() => image ? [image] : [], [image]);
  const urls = useUrls(images);

  return urls?.[0] || '';
}
