import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BaseAppDialog from '../../app/components/BaseAppDialog';
import { useAddEditMySwap } from '../../app/hooks/useUpdateProfile';
import { MySwapDraft } from '../../app/types';
import StepOne from './StepOne';
import StepTwo from './StepTwo';

interface Props {
  swap: MySwapDraft | undefined;
  onClose: () => void;
}

export default function AddEditSwapDialog({ swap, onClose }: Props) {
  const { t } = useTranslation();
  const [mySwap, setMySwap] = useState<MySwapDraft | undefined>(swap ? cloneDeep(swap) : {});
  const isNew = useMemo(() => !swap?.id, [swap]);
  const open = useMemo(() => !!swap, [swap]);
  const [step, setStep] = useState<'step1' | 'step2'>(isNew ? 'step1' : 'step2');
  const { addEditMySwap } = useAddEditMySwap();

  const submitBtnText = useMemo(() => {
    if (step === 'step1') return t('myswapbox.button_next');
    if (isNew) return t('myswapbox.button_add');
    return t('myswapbox.button_finish_edit');
  }, [step, isNew]);

  const handleClose = useCallback(() => {
    setStep('step1');
    setMySwap({});
    onClose();
  }, []);

  const handleSubmit = useCallback(async () => {
    if (step === 'step1') return setStep('step2');

    if (!mySwap?.jewelryType || !mySwap?.swap) return;
    await addEditMySwap(mySwap);

    handleClose();
  }, [step, mySwap]);

  useEffect(() => {
    if (isNew) return;

    setMySwap(cloneDeep(swap));
    setStep('step2');
  }, [isNew]);

  return (
    <BaseAppDialog fullScreen
      open={ open } title={ isNew ? t('myswapbox.add_dialog_title') : t('myswapbox.edit_dialog_title') }
      valid={ !!mySwap?.jewelryType && !!mySwap?.swap }
      submitBtnText={ submitBtnText }
      onDismiss={ handleClose } onSubmit={ handleSubmit }
    >
      { step === 'step1'
        ? <StepOne onUpdate={ setMySwap } />
        : mySwap && <StepTwo swap={ mySwap } onUpdate={ setMySwap } />
      }
    </BaseAppDialog>
  );
}
