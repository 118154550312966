import {
  AppBar, Button, Dialog, DialogActions, DialogContent, DialogProps,
  DialogTitle, IconButton, makeStyles, Toolbar, Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { PropsWithChildren, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { DialogTransition, FsDIalogTransition } from './DialogTransition';

const useStyles = makeStyles((theme) => ({
  actions: {
    justifyContent: 'space-between',
    padding: theme.spacing(2, 3),
  },
}));

interface Props {
  dialogProps?: Partial<DialogProps>;
  onDismiss?: () => void;
  onSubmit?: () => void;
  open: boolean;
  title: string;
  fullScreen?: boolean;
  fullWidth?: boolean;
  valid?: boolean;
  submitBtnText?: string;
  dismissBtnText?: string;
}

export default function BaseAppDialog({
  children, dialogProps, onDismiss, onSubmit,
  open, title, fullScreen, valid = true,
  submitBtnText, dismissBtnText, fullWidth,
}: PropsWithChildren<Props>) {
  const dialogRef = useRef();
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      open={ open } fullScreen={ fullScreen } onAbort={ onDismiss }
      ref={ dialogRef } fullWidth={ fullWidth }
      TransitionComponent={ fullScreen ? FsDIalogTransition : DialogTransition }
      { ...dialogProps }
    >
      { fullScreen
        ? (
          <AppBar color="transparent" style={ { position: 'relative' } }>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={ onDismiss }
                aria-label="close"
              >
                <Close />
              </IconButton>
              <Typography style={ { marginLeft: '2px', flex: 1 } } variant="h6" component="div">
                { title }
              </Typography>
            </Toolbar>
          </AppBar>
        )
        : (<DialogTitle>{ title }</DialogTitle>)
      }
      <DialogContent>
        { children }
      </DialogContent>
      <DialogActions className={ classes.actions }>
        <Button onClick={ onDismiss } variant="outlined">
          { dismissBtnText || t('general.button_cancel') }
        </Button>
        <Button onClick={ onSubmit } variant="contained" color="primary" disabled={ !valid }>
          { submitBtnText || t('general.button_ok') }
        </Button>
      </DialogActions>
    </Dialog>
  );
}
