import { useState } from 'react';
import { useUser } from 'reactfire';
import { useAsyncEffect } from 'use-async-effect';

export default function useIsAdmin() {
  const user = useUser();
  const [isAdmin, setIsAdmin] = useState(false);

  useAsyncEffect(async (isMounted) => {
    const isAdmin = !!(await user?.data?.getIdTokenResult())?.claims?.admin;

    if (!isMounted()) return;

    setIsAdmin(isAdmin);
  }, [user]);

  return isAdmin;
}
