import { useEffect, useState } from 'react';

import useAppFirestoreDocData from '../../app/hooks/useAppFirestoreDocData';
import useLocaleDocRef from '../../app/hooks/useLocaleDocRef';
import useUrl from '../../app/hooks/useUrl';

interface CmsData {
  title: string;
  image: string;
  loginTitle: string;
  instantLoginMsg: string;
  alreadyHaveAccountTitle: string;
  createAccountTitle: string;
}

export default function useLoginData() {
  const ref = useLocaleDocRef<CmsData>('pages/signin-signup-page');
  const { data, loading: dataLoading } = useAppFirestoreDocData(ref);
  const [loading, setLoading] = useState(true);

  const [loginContent, setLoginContent] = useState({
    title: '',
    loginTitle: '',
    instantLoginMsg: '',
    alreadyHaveAccountTitle: '',
    createAccountTitle: '',
  });
  const [image, setImage] = useState<string>('');
  const imageUrl = useUrl(image);

  useEffect(() => {
    if (dataLoading || !data) return;
    setLoading(false);
    setImage(data.image);
    setLoginContent({
      title: data.title,
      loginTitle: data.loginTitle,
      instantLoginMsg: data.instantLoginMsg,
      alreadyHaveAccountTitle: data.alreadyHaveAccountTitle,
      createAccountTitle: data.createAccountTitle,
    });
  }, [data, dataLoading]);

  return { loginContent, imageUrl, loading };
}

export type LoginPageData = ReturnType<typeof useLoginData>;
