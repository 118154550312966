import { Grid, Input, Typography } from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import BaseAppDialog from '../../../app/components/BaseAppDialog';
import useAddresses from '../../../app/hooks/useAddresses';
import { Address } from '../../../app/types';

interface Props {
  type: Address['type'];
  onClose: () => void;
  address?: Address;
  open: boolean;
}

export default function AddressModal({ open, type, onClose, address }: Props) {
  const { t } = useTranslation();
  const { addEditAddress } = useAddresses();
  const [receiver, setReceiver] = useState(address?.receiver || '');
  const [address1, setAddress1] = useState(address?.address1 || '');
  const [address2, setAddress2] = useState(address?.address2 || '');
  const [zip, setZip] = useState(address?.zip || '');
  const [city, setCity] = useState(address?.city || '');
  const [country, setCountry] = useState(address?.country || '');

  const isValid = !!receiver && !!address1 && !!zip && !!city && !!country;

  const handleSave = () => {
    if (!addEditAddress || !isValid) return;
    addEditAddress({ id: address?.id, receiver, address1, address2, zip, city, country, type });
    setReceiver('');
    setAddress1('');
    setAddress2('');
    setZip('');
    setCity('');
    setCountry('');
    onClose();
  };

  return (
    <BaseAppDialog
      title={ t(`profile.${ type.toLowerCase() }`) }
      open={ open } onDismiss={ onClose } onSubmit={ handleSave }
      submitBtnText={ t('profile.save_address') } fullWidth
      valid={ isValid }
    >
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 }>
          <Typography variant="subtitle2">{ t('profile.receiver') }</Typography>
          <Input fullWidth value={ receiver } name="name" onChange={ (e) => setReceiver(e.target.value) } />
        </Grid>
        <Grid item xs={ 12 }>
          <Typography variant="subtitle2">{ t('profile.address_1') }</Typography>
          <Input fullWidth value={ address1 } onChange={ (e) => setAddress1(e.target.value) } />
        </Grid>
        <Grid item xs={ 12 }>
          <Typography variant="subtitle2">{ t('profile.address_2') }</Typography>
          <Input fullWidth value={ address2 } onChange={ (e) => setAddress2(e.target.value) } />
        </Grid>
        <Grid item xs={ 12 } sm={ 4 }>
          <Typography variant="subtitle2">{ t('profile.zip') }</Typography>
          <Input fullWidth value={ zip } onChange={ (e) => setZip(e.target.value) } />
        </Grid>
        <Grid item xs={ 12 } sm={ 8 }>
          <Typography variant="subtitle2">{ t('profile.city') }</Typography>
          <Input fullWidth value={ city } onChange={ (e) => setCity(e.target.value) } />
        </Grid>
        <Grid item xs={ 12 }>
          <Typography variant="subtitle2">{ t('profile.country') }</Typography>
          <Input fullWidth value={ country } onChange={ (e) => setCountry(e.target.value) } />
        </Grid>
      </Grid>
    </BaseAppDialog>
  );
}
