import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';

import giftBoxLeft1 from './assets/giftBoxLeft1.svg';
import giftBoxLeft2 from './assets/giftBoxLeft2.svg';
import giftBoxLeft3 from './assets/giftBoxLeft3.svg';
import giftBoxRight1 from './assets/giftBoxRight1.svg';
import giftBoxRight2 from './assets/giftBoxRight2.svg';
import giftBoxRight3 from './assets/giftBoxRight3.svg';

const useStyles = makeStyles(theme => ({
  outerContainer: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(5),
    alignItems: 'center',
    justifyContent: 'center',
  },
  sideImageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: '3%', // important to be margin
    paddingRight: '3%', // important to be padding
    width: '20%',

    '& img' :{
      padding: '13% 0',
      width: '70%',
      alignSelf: 'end',

      '&:nth-child(2)': {
        alignSelf: 'start',
      },
    },
  },
  rightImageContainer: {
    '& img' :{
      alignSelf: 'start',

      '&:nth-child(2)': {
        alignSelf: 'end',
      },
    },
  },
  centerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function GiftsImageContainer({
  children,
  className,
  imagesClassName,
}: PropsWithChildren<{ className?: string, imagesClassName?: string }>) {
  const styles = useStyles();

  return (
    <Box className={ styles.outerContainer }>
      <Box className={ clsx(styles.sideImageContainer, imagesClassName) }>
        <img src={ giftBoxLeft1 } />
        <img src={ giftBoxLeft2 } />
        <img src={ giftBoxLeft3 } />
      </Box>

      <Box className={ clsx(styles.centerContainer, className) }>
        { children }
      </Box>

      <Box className={ clsx(styles.sideImageContainer, styles.rightImageContainer, imagesClassName) }>
        <img src={ giftBoxRight1 } />
        <img src={ giftBoxRight2 } />
        <img src={ giftBoxRight3 } />
      </Box>
    </Box>
  );
}
