import { Card, CardActions, CardContent, Grid, makeStyles, Typography } from '@material-ui/core';
import { memo, PropsWithChildren, ReactNode } from 'react';

const useStyles = makeStyles(theme => ({
  grid: {
    margin: theme.spacing(2, 0),
  },
  card: {
    padding: theme.spacing(1),
  },
  aboutTitle: {
    marginBottom: theme.spacing(2),
  },
}));

function GridItemCard({ title, actions, children }: PropsWithChildren<{ title?: string; actions?: ReactNode | ReactNode[] }>) {
  const classes = useStyles();

  return (
    <Grid item className={ classes.grid }>
      <Card className={ classes.card }>
        <CardContent>
          { !!title &&
            <Typography variant="h5" component="h2" className={ classes.aboutTitle }>{ title }</Typography>
          }
          { children }
        </CardContent>
        { !!actions &&
          <CardActions>{ actions }</CardActions>
        }
      </Card>
    </Grid>
  );
}

export default memo(GridItemCard);
