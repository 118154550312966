export enum JewelryTypesOptions {
  STUD_EARRINGS = 'STUD_EARRINGS',
  DROPLET_EARRINGS = 'DROPLET_EARRINGS',
  KREOLES = 'KREOLES',
  RINGS = 'RINGS',
  PENDANTS = 'PENDANTS',
  NECKLACES = 'NECKLACES',
  BRACELETS = 'BRACELETS',
  SETS = 'SETS',
}

export enum JewelryCategoriesOptions {
  GEMSTONE_JEWELRY = 'GEMSTONE_JEWELRY',
  GOLD_PLATED_JEWELRY = 'GOLD_PLATED_JEWELRY',
  GOLD_JEWELRY = 'GOLD_JEWELRY',
  SILVER_JEWELRY = 'SILVER_JEWELRY',
}

export enum GemstonesColorOptions {
  BLUE = 'BLUE',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
}

export enum GemstoneCutOptions {
  CABOCHON = 'CABOCHON',
  FACETED = 'FACETED',
}

export enum GemstoneSettingOptions {
  BEZEL_SETTINGS = 'BEZEL_SETTINGS',
  PRONG_SETTINGS = 'PRONG_SETTINGS',
}

export enum StoneSizesOptions {
  SMALL_STONE = 'SMALL_STONE',
  BIG_STONE = 'BIG_STONE',
}

export enum EaringSizesOptions {
  SHORT_EARING = 'SHORT_EARING',
  LONG_EARING = 'LONG_EARING',
}

export enum PendantSizesOptions {
  SMALL_PENDANT = 'SMALL_PENDANT',
  BIG_PENDANT = 'BIG_PENDANT',
}

export enum StoneTypesOptions {
  PURE_STONE = 'PURE_STONE',
  MIXED_STONES = 'MIXED_STONES',
}

export enum SilverFinishOptions {
  POLISHED_SURFACE = 'POLISHED_SURFACE',
  ROUGH_SURFACE = 'ROUGH_SURFACE',
}

export enum SymbolOptions {
  FLOWER_OF_LIFE = 'FLOWER_OF_LIFE',
  TREE_OF_LIFE = 'TREE_OF_LIFE',
  ANIMALS = 'ANIMALS',
  FLOWERS = 'FLOWERS',
  LEAVES = 'LEAVES',
}
