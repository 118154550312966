import { Box, Button, Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthContext } from '../../app/components/AuthProvider/hooks';
import MultiSelectBox from '../../app/components/MultiSelectBox';
import {
  GemstoneCutOptions, GemstonesColorOptions, GemstoneSettingOptions, JewelryCategoriesOptions,
  JewelryTypesOptions, ProductPreferences, SilverFinishOptions, SymbolOptions,
} from '../../app/types';
import GridItemCard from './GridItemCard';

export default function ProductPreferencesConfig() {
  const { t } = useTranslation();
  const { profile, updateProfile } = useAuthContext();
  const [productPreferences, setProductPreferences] = useState<ProductPreferences>(profile?.productPreferences || {});

  useEffect(() => {
    if (!profile?.productPreferences) return;
    setProductPreferences(profile.productPreferences);
  }, [profile]);

  const savePreferences = () => {
    if (!updateProfile) return;
    updateProfile({ productPreferences });
  };

  return (
    <GridItemCard title={ t('profile.product_preferences') }>
      <Grid container spacing={ 1 }>
        <Grid item md={ 6 } xs={ 12 }>
          <MultiSelectBox i18nNamespace="select_jewelry_categories"
            value={ productPreferences.jewelryCategories || [] }
            options={ JewelryCategoriesOptions }
            onChange={ jewelryCategories => setProductPreferences(current => ({ ...current, jewelryCategories })) } />
        </Grid>
        <Grid item md={ 6 } xs={ 12 }>
          <MultiSelectBox i18nNamespace="select_jewelry_types"
            value={ productPreferences.jewelryTypes || [] }
            options={ JewelryTypesOptions }
            onChange={ jewelryTypes => setProductPreferences(current => ({ ...current, jewelryTypes })) } />
        </Grid>
        <Grid item md={ 6 } xs={ 12 }>
          <MultiSelectBox i18nNamespace="select_gemstone_colors"
            value={ productPreferences.gemstonesColor || [] }
            options={ GemstonesColorOptions }
            onChange={ gemstonesColor => setProductPreferences(current => ({ ...current, gemstonesColor })) } />
        </Grid>
        <Grid item md={ 6 } xs={ 12 }>
          <MultiSelectBox i18nNamespace="select_gemstone_cut"
            value={ productPreferences.gemstoneCut || [] }
            options={ GemstoneCutOptions }
            onChange={ gemstoneCut => setProductPreferences(current => ({ ...current, gemstoneCut })) } />
        </Grid>
        <Grid item md={ 6 } xs={ 12 }>
          <MultiSelectBox i18nNamespace="select_gemstone_setting"
            value={ productPreferences.gemstoneSetting || [] }
            options={ GemstoneSettingOptions }
            onChange={ gemstoneSetting => setProductPreferences(current => ({ ...current, gemstoneSetting })) } />
        </Grid>
        <Grid item md={ 6 } xs={ 12 }>
          <MultiSelectBox i18nNamespace="select_silver_finish"
            value={ productPreferences.silverFinish || [] }
            options={ SilverFinishOptions }
            onChange={ silverFinish => setProductPreferences(current => ({ ...current, silverFinish })) } />
        </Grid>
        <Grid item md={ 6 } xs={ 12 }>
          <MultiSelectBox i18nNamespace="select_symbol"
            value={ productPreferences.symbol || [] }
            options={ SymbolOptions }
            onChange={ symbol => setProductPreferences(current => ({ ...current, symbol })) } />
        </Grid>
      </Grid>

      <Box marginTop={ 3 } display="flex" justifyContent="flex-end">
        <Button variant="contained" color="primary" onClick={ savePreferences }>{ t('general.save_changes') }</Button>
      </Box>
    </GridItemCard>
  );
}
