// TODO: change this after material ui v5 update
import { unstable_createMuiStrictModeTheme as createTheme } from '@material-ui/core/styles';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';

const palette: PaletteOptions = {
  type: 'light',
  primary: {
    main: '#c67600',
    // main: '#ffd700',
  },
  secondary: {
    // main: '#EDC18D',
    // main: '#FFF',
    // main: '#cf352e',
    main: '#F8FAEE',
    contrastText: '#000',
  },
  background: {
    default: 'hsl(0, 0%, 97%)', // #e5e5e5
    // default: 'hsl(0, 70%, 18%)',
    // default: '#42201f',
    // paper: '#732F2D',
    // paper: '#6f4846',
    paper: 'hsl(0, 0%, 100%)',
    // paper: 'hsl(0, 70%, 24%)',
    // paper: 'hsl(7.3, 22.7%, 35.5%)',
  },
};

const typography = {
  h1: {
    fontFamily: 'ProzaLibre',
    fontSize: '3rem',
  },
  h2: {
    fontFamily: 'ProzaLibre',
    fontSize: '2.5rem',
  },
  h3: {
    fontFamily: 'ProzaLibre',
    fontSize: '2rem',
  },
  h4: {
    fontFamily: 'ProzaLibre',
    fontSize: '1.75rem',
  },
  h5: {
    fontFamily: 'ProzaLibre',
    fontSize: '1.5rem',
  },
  h6: {
    fontFamily: 'ProzaLibre',
    fontSize: '1rem',
  },
  subtitle1: {
    fontFamily: 'ProzaLibre',
    fontSize: '0.875rem',
  },
};

const theme = createTheme({
  palette,
  typography,
});

export { theme };
