import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface Props {
    open: boolean;
    handleClose: (value: boolean) => void;
}

export default function DeleteConfirmation({ open, handleClose }:Props) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={ open }
      onClose={ () => handleClose(false) }
    >
      <DialogTitle>{ t('delete_confirmation.title') }</DialogTitle>
      <DialogContent>
        <DialogContentText>
          { t('delete_confirmation.description') }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={ () => handleClose(false) } color="primary">
          { t('delete_confirmation.btn_no') }
        </Button>
        <Button onClick={ () => handleClose(true) } color="primary" autoFocus>
          { t('delete_confirmation.btn_yes') }
        </Button>
      </DialogActions>
    </Dialog>
  );
}
