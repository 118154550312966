import FaqList from '../../app/components/FaqList';
import usePageTitle from '../../app/hooks/usePageTitle';
import Gallery from './Gallery';
import Header from './Header/index';
import Spacer from './Spacer';
import Steps from './Steps/index';
import SwapboxSection from './SwapboxSection';
import useHomepageData from './useHomepageData';
import WhatYouGet from './WhatYoutGet';

export interface Image {
  image: string;
  title?: string;
  description?: string;
}

export interface HomeData {
  header: {
    logo: string,
    subtitle1: string,
    subtitle2: string,
    subscribeBoxContent: string,
  };
  steps: {
    title: string,
    steps: {
      icon: string,
      title: string,
      paragraph: string,
      images?: string[],
      earrings?: string[],
      rings?: string[],
    }[],
  };
  wyg: {
    title: string,
    wyg: { title: string, image: string }[],
  },
  startNow: {
    content: string,
  };
  footer: {
    first: {
      title: string,
      content: string,
    },
    second: {
      title: string,
      content: string,
    },
  };
  swapBoxData: {
    title: string,
    subtitle: string,
    swapBoxes: {
      icon: string,
      title: string,
      paragraph: string,
    }[]
  },
  gallery: {
    title: string,
    galleryImages: string[],
  }
}

export default function HomePage() {
  const { loading, headerData, stepsData, wygData, galleryData, swapBoxData, faqTitle } = useHomepageData();
  usePageTitle('home.page_title', '');

  if (loading) return <div>loading...</div>;

  return (
    <div>
      <Header { ...headerData } />
      <Steps { ...stepsData } />
      <Spacer />
      <WhatYouGet { ...wygData } />
      <Spacer />
      <Gallery { ...galleryData } />
      <Spacer />
      <SwapboxSection { ...swapBoxData }/>
      <Spacer />
      <FaqList filterHomeEntries faqTitle={ faqTitle } />
    </div>
  );
}
