import { Card, makeStyles, Typography } from '@material-ui/core';

import useUrl from '../../../app/hooks/useUrl';
import { WygTile } from '../useXmaspageData';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    boxSizing: 'border-box',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1, 4),
    marginBottom: theme.spacing(6),
    width: 180,
    height: 240,
    textAlign: 'center',
    '@media(max-width: 650px)': {
      marginBottom: theme.spacing(3),
    },
  },
  text: {
    fontWeight: 300,
    fontSize: '13px',
    lineHeight: '22px',
  },
  image: {
    height: 100,
    width: 100,
  },
}));

export default function Header({ tile }: { tile: WygTile }) {
  const { title, image } = tile || {};
  const classes = useStyles();
  const url = useUrl(image);

  return (
    <Card className={ classes.card }>
      <img src={ url } className={ classes.image } />
      <Typography variant="h3" className={ classes.text } >{ title || '' }</Typography>
    </Card>
  );
}
