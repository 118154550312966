
import { Box, Button, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useFormik } from 'formik';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'reactfire';
import { object, ref, string } from 'yup';

import FormikTextField from '../../app/components/FormikTextField';
import RoundedButton from './RoundedButton';

interface Props {
  title: string;
  cancel: () => void;
}

const useStyles = makeStyles({
  registrationForm: {
    width: '100%',
  },
});

export default function PasswordRegister({ title, cancel }: Props) {
  const { t } = useTranslation();
  const auth = useAuth();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const validationSchema = useMemo(() => object({
    email: string()
      .required(t('login.message_emailRequired'))
      .email(t('login.message_invalidEmail')),
    password: string()
      .required(t('login.message_passwordRequired'))
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/,
        t('login.message_passwordInvalidFormat'),
      ),
    confirmPassword: string()
      .required(t('login.message_passwordConfirmationRequired'))
      .oneOf(
        [ref('password')],
        t('login.message_passwordNotMatch'),
      ),
  }), []);

  const formik = useFormik({
    initialValues: { email: '', password: '', confirmPassword: '' },
    validationSchema,
    onSubmit: async ({ email, password }) => {
      setErrorMsg('');
      setLoading(true);

      try {
        await createUserWithEmailAndPassword(auth, email, password);
      }
      catch (error) {
        if (error instanceof Error) setErrorMsg(error.message);
        else setErrorMsg(t('login.message_registrationError'));
      }
      setLoading(false);
    },
  });

  return (
    <form onSubmit={ formik.handleSubmit } className={ classes.registrationForm }>
      { errorMsg !== '' &&
        <MuiAlert elevation={ 6 } variant="filled" severity="error">{ errorMsg }</MuiAlert>
      }
      <Typography variant="h4">{ title }</Typography>
      <Box my={ 2 }>
        <FormikTextField formik={ formik } name="email" fullWidth label={ t('login.label_email') } />
      </Box>

      <Box my={ 2 }>
        <FormikTextField formik={ formik } name="password" type="password" fullWidth label={ t('login.label_password') } />
      </Box>

      <Box my={ 2 }>
        <FormikTextField formik={ formik } name="confirmPassword" type="password" fullWidth label={ t('login.label_confirmPassword') } />
      </Box>
      <Box my={ 2 } display="flex" justifyContent="space-between">
        <Button variant="text" onClick={ () => cancel() }>{ t('login.button_cancel') }</Button>
        <RoundedButton
          variant="contained"
          color="primary"
          type="submit"
          disabled={ !!Object.keys(formik.errors).length || loading }
        >
          { t('login.button_register') }
          { loading && <CircularProgress color="inherit" size={ 16 } /> }
        </RoundedButton>
      </Box>
    </form>
  );
}
