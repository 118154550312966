import { Button, Grid, Input, Typography } from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthContext, useProfile } from '../../app/components/AuthProvider/hooks';
import GridItemCard from './GridItemCard';

export default function PersonalInformation() {
  const { t } = useTranslation();
  const { updateProfile } = useAuthContext();
  const profile = useProfile();
  const displayName = profile?.displayName;
  const email = profile?.email;
  const [userFirstName, setUserFirstName] = useState(profile?.firstname || '');
  const [userLastName, setUserLastName] = useState(profile?.lastname || '');
  const [phoneNumber, setPhoneNumber] = useState(profile?.phoneNumber || '');
  const isDisabled = !userFirstName || !userLastName || !phoneNumber;

  const handleClick = () => {
    if (!updateProfile) return;
    if (!userFirstName && !userLastName && !phoneNumber) return;
    updateProfile({ firstname: userFirstName, lastname: userLastName, phoneNumber });
  };

  return (
    <GridItemCard title={ t('profile.about_me') }>
      <Grid container spacing={ 2 }>
        <Grid item sm={ 6 } xs={ 12 }>
          <Typography variant="subtitle2">{ t('profile.username') }</Typography>
          <Typography variant="body1">{ displayName }</Typography>
        </Grid>
        <Grid item sm={ 6 } xs={ 12 }>
          <Typography variant="subtitle2">{ t('profile.email') }</Typography>
          <Typography variant="body1">{ email }</Typography>
        </Grid>

        <Grid item sm={ 6 } xs={ 12 }>
          <Typography variant="subtitle2">{ t('profile.firstname') }</Typography>
          <Input fullWidth value={ userFirstName } onChange={ (e) => setUserFirstName(e.target.value) } />
        </Grid>
        <Grid item sm={ 6 } xs={ 12 }>
          <Typography variant="subtitle2">{ t('profile.lastname') }</Typography>
          <Input fullWidth value={ userLastName } onChange={ (e) => setUserLastName(e.target.value) }/>
        </Grid>

        <Grid item sm={ 6 } xs={ 12 }>
          <Typography variant="subtitle2">{ t('profile.phone') }</Typography>
          <Input fullWidth value={ phoneNumber } onChange={ (e) => setPhoneNumber(e.target.value) } />
        </Grid>

        <Grid item container justifyContent="flex-end">
          <Button disabled={ isDisabled } variant="outlined" onClick={ handleClick }>
            { t('profile.update_profile') }
          </Button>
        </Grid>
      </Grid>
    </GridItemCard>
  );
}
