import { Box, FormControl, Grid, makeStyles, Slider, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import MultiSelectBox from '../../app/components/MultiSelectBox';
import {
  EaringSizesOptions, GemstoneCutOptions, GemstoneSettingOptions,
  JewelryCategoriesOptions, MySwapDraft, PendantSizesOptions, SilverFinishOptions,
  StoneSizesOptions, StoneTypesOptions,
  SymbolOptions } from '../../app/types';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '90%',
  },
}));

interface Props {
  swap: MySwapDraft;
  onUpdate: (value: MySwapDraft) => void;
}

export default function StepTwo({ swap, onUpdate }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    jewelryCategories,
    gemstoneCut,
    gemstoneSetting,
    stoneSizes,
    earingSizes,
    pendantSizes,
    stoneTypes,
    silverFinish,
    symbol,
    stoneCount,
  } = swap;

  return (
    <Box>
      <Grid container spacing={ 1 }>
        <Grid item md={ 6 } sm={ 12 } xs={ 12 }>
          <MultiSelectBox i18nNamespace="select_jewelry_categories"
            value={ jewelryCategories || [] }
            options={ JewelryCategoriesOptions }
            onChange={ values => onUpdate({ ...swap, jewelryCategories: values }) } />
        </Grid>
        <Grid item md={ 6 } sm={ 12 } xs={ 12 }>
          <MultiSelectBox i18nNamespace="select_gemstone_cut"
            value={ gemstoneCut || [] }
            options={ GemstoneCutOptions }
            onChange={ values => onUpdate({ ...swap, gemstoneCut: values }) } />
        </Grid>
        <Grid item md={ 6 } sm={ 12 } xs={ 12 }>
          <MultiSelectBox i18nNamespace="select_gemstone_setting"
            value={ gemstoneSetting || [] }
            options={ GemstoneSettingOptions }
            onChange={ values => onUpdate({ ...swap, gemstoneSetting: values }) } />
        </Grid>
        <Grid item md={ 6 } sm={ 12 } xs={ 12 }>
          <MultiSelectBox i18nNamespace="select_stone_size"
            value={ stoneSizes || [] }
            options={ StoneSizesOptions }
            onChange={ values => onUpdate({ ...swap, stoneSizes: values }) } />
        </Grid>
        <Grid item md={ 6 } sm={ 12 } xs={ 12 }>
          <MultiSelectBox i18nNamespace="select_earing_size"
            value={ earingSizes || [] }
            options={ EaringSizesOptions }
            onChange={ values => onUpdate({ ...swap, earingSizes: values }) } />
        </Grid>
        <Grid item md={ 6 } sm={ 12 } xs={ 12 }>
          <MultiSelectBox i18nNamespace="select_pendant_size"
            value={ pendantSizes || [] }
            options={ PendantSizesOptions }
            onChange={ values => onUpdate({ ...swap, pendantSizes: values }) } />
        </Grid>
        <Grid item md={ 6 } sm={ 12 } xs={ 12 }>
          <MultiSelectBox i18nNamespace="select_stone_type"
            value={ stoneTypes || [] }
            options={ StoneTypesOptions }
            onChange={ values => onUpdate({ ...swap, stoneTypes: values }) } />
        </Grid>
        <Grid item md={ 6 } sm={ 12 } xs={ 12 }>
          <MultiSelectBox i18nNamespace="select_silver_finish"
            value={ silverFinish || [] }
            options={ SilverFinishOptions }
            onChange={ values => onUpdate({ ...swap, silverFinish: values }) } />
        </Grid>
        <Grid item md={ 6 } sm={ 12 } xs={ 12 }>
          <FormControl className={ classes.formControl }>
            <Typography>{ t('myswapbox.stone_count') }</Typography>
            <Slider
              defaultValue={ 0 }
              max={ 8 }
              valueLabelDisplay="auto"
              step={ 1 }
              marks
              value={ stoneCount || 0 }
              onChange={ (_, value) => onUpdate({ ...swap, stoneCount: value as number }) }
            />
          </FormControl>
        </Grid>
        <Grid item md={ 6 } sm={ 12 } xs={ 12 }>
          <MultiSelectBox i18nNamespace="select_symbol"
            value={ symbol || [] }
            options={ SymbolOptions }
            onChange={ values => onUpdate({ ...swap, symbol: values }) } />
        </Grid>
      </Grid>
    </Box>
  );
}
