import { useLocation } from 'react-router-dom';

import { IS_PRODUCTION } from '../utils';
import useIsAdmin from './useIsAdmin';

export default function useHasAlphaAccess() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const isAdmin = useIsAdmin();

  return !IS_PRODUCTION || !!params.get('admin') || !!isAdmin;
}
