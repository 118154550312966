import { Box, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';

import { HomeData } from '..';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

export interface Inspiration {
  title: string,
  description: string,
  image?: string
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(10, 6, 0),
    margin: '0 auto',
    maxWidth: '1280px',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(10, 2, 0),
    },
    [theme.breakpoints.down(600)]: {
      padding: theme.spacing(3, 2),
    },
  },
  headline: {
    fontSize: 'clamp(2rem, 6vw, 4rem)',
    textAlign: 'center',
  },
}));

export default function Steps({ title, steps }: HomeData['steps']) {
  const classes = useStyles();
  const [step1, step2, step3, step4] = steps;
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div className={ classes.container }>
      <Box mb={ 4 }>
        <Typography className={ classes.headline } variant="h4" color="primary">{ title }</Typography>
      </Box>
      <Step1 { ...step1 } />
      <Box display="flex" flexDirection={ isSmall ? 'column' : 'row' } justifyContent="space-between">
        <Step2 { ...step2 } />
        <Step3 { ...step3 } />
        <Step4 { ...step4 } />
      </Box>
    </div>
  );
}
