import { Query, query } from 'firebase/firestore';
import { useMemo } from 'react';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import useCollectionRef from '../../hooks/useCollectionRef';

export default function useFAQEntryIds() {
  const firestore = useFirestore();
  const faqsCollection = useCollectionRef<{ id: string }>(firestore, 'faqs');
  const faqsQuery = query(faqsCollection) as Query<{ id: string }>;
  const data = useFirestoreCollectionData(faqsQuery, { idField: 'id' });

  const { faqIds, loading } = useMemo(() => ({
    faqIds: data.data?.map(({ id }) => id) || [],
    loading: data.status === 'loading',
  }), [data]);

  return { faqIds, loading };
}
