import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles(theme => ({
  markdown: {
    margin: 'auto',
    ...theme.typography.body1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    '& h1': {
      ...theme.typography.h2,
    },
    '& h2': {
      ...theme.typography.h3,
    },
    '& h3': {
      ...theme.typography.h4,
    },
    '& h4': {
      ...theme.typography.h5,
    },
    '& a': {
      color: theme.palette.secondary.dark,
      wordBreak: 'break-word',
    },
    '& img': {
      maxWidth: '100%',
    },

    [theme.breakpoints.up('sm')]: {
      maxWidth: '650px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '900px',
    },
  },
}));

interface Props {
  className?: string,
  content: string,
}

export default function Markdown({ className, content }: Props) {
  const classes = useStyles();

  return (
    <ReactMarkdown
      className={ clsx(classes.markdown, className) }
      components={ {
        a({ href, children }) {
          if (children?.[0] === 'anchor') return <a id={ href } />;
          if (href?.startsWith('http')) return <a href={ href } target="_blank" rel="noreferrer">{ children }</a>;
          return <a href={ href }>{ children }</a>;
        },
      } }
    >
      { content }
    </ReactMarkdown>
  );
}
