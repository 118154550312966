import { useCallback, useEffect, useState } from 'react';
import { getCookieConsentValue } from 'react-cookie-consent';

import { useAuthContext } from '../AuthProvider/hooks';

export default function useCookieConsent() {
  const [cookieConsent, setCookieConsent] = useState(!!getCookieConsentValue());
  const { isAuthenticated } = useAuthContext();

  useEffect(() => setCookieConsent(isAuthenticated || !!getCookieConsentValue()), [isAuthenticated]);

  const acceptCookies = useCallback(() => setCookieConsent(!!getCookieConsentValue()), []);

  return { cookieConsent, acceptCookies };
}
