import { useEffect, useState } from 'react';

import useAppFirestoreDocData from '../../app/hooks/useAppFirestoreDocData';
import useLocaleDocRef from '../../app/hooks/useLocaleDocRef';
import useUrl from '../../app/hooks/useUrl';

interface CmsData {
  title: string;
  subTitle: string;
  content: string;
  swapboxImage: string;
}

export default function useSwapboxData() {
  const ref = useLocaleDocRef<CmsData>('pages/swapbox-content');
  const { data, loading } = useAppFirestoreDocData(ref);
  const [headerData, setHeaderData] = useState({
    title: '',
    subTitle: '',
    content: '',
    swapboxImage: '',
  });
  const image = useUrl(data?.swapboxImage);

  useEffect(() => {
    if (loading || !data) return;
    setHeaderData({
      title: data.title,
      subTitle: data.subTitle,
      content: data.content,
      swapboxImage: image,
    });
  }, [data, image, loading]);

  return { headerData, loading };
}
