import { IconButton } from '@material-ui/core';
import { AllInbox, Login, Logout, Person } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'reactfire';

import { useAuthContext, useProfile } from '../../AuthProvider/hooks';

export default function ToolbarActions() {
  const { isAuthenticated } = useAuthContext();
  const history = useHistory();
  const auth = useAuth();
  const profile = useProfile();

  const logout = () => {
    auth.signOut();
    history.push('/home');
  };

  if (!isAuthenticated) return (
    <IconButton
      aria-label="login"
      onClick={ () => history.push('/login', { page: history.location.pathname }) }>
      <Login fontSize="inherit" />
    </IconButton>
  );

  return (
    <div>
      { profile?.active &&
        <IconButton aria-label="swapbox" color="primary" onClick={ () => history.push('/swapbox') }>
          <AllInbox fontSize="inherit" />
        </IconButton>
      }
      <IconButton aria-label="profile" color="primary" onClick={ () => history.push('/profile') }>
        <Person fontSize="inherit" />
      </IconButton>
      <IconButton aria-label="logout" color="primary" onClick={ logout }>
        <Logout fontSize="inherit" />
      </IconButton>
    </div>
  );
}
