import { alpha, darken, makeStyles } from '@material-ui/core';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';

import useCookieConsent from './useCookieConsent';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: theme.spacing(8),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: alpha(darken(theme.palette.background.default, .2), .9),
    fontSize: 20,
    padding: theme.spacing(2, 5),
    textAlign: 'right',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    zIndex: 2,
    boxShadow: theme.shadows[3],
  },
  button: {
    ...theme.typography.button,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    boxShadow: theme.shadows[3],
    border: 'none',
    whiteSpace: 'nowrap',
    padding: theme.spacing(1, 2),
    marginLeft: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    fontWeight: 'bold',
    transition: 'background-color .2s ease-in-out',

    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

export default function CookieConsentBar() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { cookieConsent, acceptCookies } = useCookieConsent();

  if (cookieConsent) return null;

  return (
    <CookieConsent
      containerClasses={ classes.container }
      buttonClasses={ classes.button }
      buttonText={ t('general.cookie_consent_accept') }
      onAccept={ acceptCookies }
      disableStyles
    >
      { t('general.cookie_consent') }
    </CookieConsent>
  );
}
