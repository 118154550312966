import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Masonry } from 'masonic';

import useUrls from '../../app/hooks/useUrls';
import { HomeData } from '.';
import Headline from './Headline';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 3),
    margin: '0 auto',
    maxWidth: '950px',

    [theme.breakpoints.down(600)]: {
      padding: theme.spacing(3),
    },
  },
  headline: {
    fontSize: 'clamp(2rem, 6vw, 4rem)',
    textAlign: 'center',
  },
  image: {
    width: '100%',
    borderRadius: theme.spacing(2),
  },
}));

function MasonryImage({ data }: { data: { url: string } }) {
  const classes = useStyles();
  return <img src={ data.url } className={ classes.image }/>;
}

export default function Gallery({ title, galleryImages }: HomeData['gallery']) {
  const classes = useStyles();
  const urls = useUrls(galleryImages).map(url => ({ url }));
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const colCount = xs ? 1 : sm ? 2 : 3;

  return (
    <div className={ classes.container }>
      <Headline title={ title } />
      { urls?.length &&
        <Masonry items={ urls } render={ MasonryImage } columnGutter={ 8 * 2 } columnCount={ colCount } />
      }
    </div>
  );
}
