import { Container, makeStyles } from '@material-ui/core';

import giftBoxCenter from '../assets/giftBoxCenter.svg';
import GiftsImageContainer from '../GiftsImageContainer';
import Headline from '../Headline';
import { PickCmsData } from '../useXmaspageData';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(6, 0),
  },
  giftsImageContainer: {
    width: '48%',

    '& img': {
      width: '100%',
    },
  },
}));

export default function Header({ title, subtitle }: PickCmsData<'title' | 'subtitle'>) {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={ classes.container }>
      <Headline title={ title } />
      <Headline title={ subtitle } />

      <GiftsImageContainer className={ classes.giftsImageContainer }>
        <img src={ giftBoxCenter } />
      </GiftsImageContainer>
    </Container>
  );
}
