import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import Cache from 'i18next-localstorage-cache';
import { initReactI18next } from 'react-i18next';

export const defaultLanguage = 'en';
export const Languages = ['en', 'de'];

i18next
  .use(Backend)
  .use(Cache)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: defaultLanguage,
    debug: false,
    whitelist: Languages,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    detection: {
      caches: [],
    },
  });

export default i18next;
