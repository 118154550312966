import { Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';

interface Props {
  size?: string;
  path?: string;
  onClick?: () => void;
}

const useStyles = makeStyles(theme => ({
  button: {
    fontWeight: 'bold',
    border: 'none',
    borderRadius: 6,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      boxShadow: '1px 1px 40px ' + theme.palette.primary.main,
    },
  },
  large: {
    padding: theme.spacing(2, 4),
    fontSize: '1.8em',
  },
}));

export default function GlowingButton({ children, size, path, onClick }: PropsWithChildren<Props>) {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    if (onClick) return onClick();
    if (path) history.push(path);
  };

  return (
    <Button
      color="primary"
      variant="contained"
      className={ clsx(classes.button, { [classes.large]: size === 'large' }) }
      onClick={ handleClick }>
      { children }
    </Button>
  );
}
