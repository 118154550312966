import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import { useProfile } from '../../app/components/AuthProvider/hooks';
import usePageTitle from '../../app/hooks/usePageTitle';
import useSwapbox from '../../app/hooks/useSwapbox';
import AddEditDialog from '../AddEditSwapDialog';
import Header from './Header';
import Swap from './Swap';
import useSwapboxData from './useSwapboxData';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(1),
    maxWidth: 800,
    margin: theme.spacing(5, 'auto', 0),
  },
  addButton: {
    margin: theme.spacing(2, 0),
    maxWidth: 300,
  },
}));

export default function SwapboxPage() {
  const styles = useStyles();
  const { t } = useTranslation();
  const { swapbox, loading } = useSwapbox();
  const { headerData } = useSwapboxData();
  const [openCreate, setOpenCreate] = useState(false);
  const profile = useProfile();
  usePageTitle('myswapbox.page_title');

  if (loading) {
    return <CircularProgress />;
  }

  if (profile && !profile.active) {
    return (<Redirect to="/profile" />);
  }

  return (
    <>
      <Header { ...headerData } />
      <div className={ styles.container }>
        <Button
          className={ styles.addButton }
          onClick = { () => setOpenCreate(true) }
          color="secondary"
          variant="contained"
          fullWidth
        >
          { t('myswapbox.button_add') }
        </Button>
        { swapbox.map((swap, i) => <Swap key={ `swap${i}` } { ...swap } />) }
      </div>
      <AddEditDialog swap={ openCreate ? {} : undefined } onClose={ () => setOpenCreate(false) } />
    </>
  );
}
