import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import jewelry from '../../../app/assets/home-header.jpg';
import { HomeData } from '..';
import GlowingButton from '../GlowingButton';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    background: `linear-gradient(to bottom, hsla(0,0%,94%,0) 80%, hsla(0,0%,94%,1)), url(${ jewelry })`,
    backgroundPosition: 'top right',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    paddingTop: theme.spacing(0),
    flexDirection: 'column',
    minHeight: '45vh',
  },
  inner: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxWidth: '100vw',
    marginLeft: 'max(8vw, 60px)',
    marginRight: '30vw',

    [theme.breakpoints.down(960)]: {
      marginRight: 'clamp(8px, 8vw, 64px)',
      marginLeft: 'clamp(8px, 8vw, 64px)',
    },
  },
  subtitle: {
    fontSize: 'clamp(2.2rem, 10vw, 6rem)',
    lineHeight: '1.1em',
    color: theme.palette.secondary.contrastText,
    margin: theme.spacing(5, 0, 2),
    textShadow: '2px 2px 6px hsl(0, 0%, 40%)',
    fontVariant: 'small-caps',
    marginBottom: '.6em',
  },
  subtitle2: {
    color: theme.palette.secondary.contrastText,
    fontSize: 'clamp(1.3rem, 3.5vw, 2.25rem)',
    lineHeight: '1.3em',
    flex: '1 0 auto',
    textShadow: '10px 10px 50px hsl(0, 0%, 30%)',
    maxWidth: '600px',

    [theme.breakpoints.down(450)]: {
      marginBottom: theme.spacing(1),
    },
  },
  buttonContainer: {
    margin: theme.spacing(5, 0, 6),
  },
}));

export default function Header({ subtitle1, subtitle2 }: HomeData['header']) {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <div className={ styles.container }>
      <div className={ styles.inner }>
        <Typography variant="h2" className={ styles.subtitle }>{ subtitle1 }</Typography>
        <Typography variant="h3" className={ styles.subtitle2 }>{ subtitle2 }</Typography>
        <div className={ styles.buttonContainer }>
          <GlowingButton path="/subscribe" size="large">{ t('home.button_subscribe') }</GlowingButton>
        </div>
      </div>
    </div>
  );
}
