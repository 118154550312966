import './index.css';

import { CssBaseline, Grow, ThemeProvider } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { SnackbarProvider } from 'notistack';
import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './app/App';
import AppFireProvider from './app/components/AppFireProvider';
import { AuthProvider } from './app/components/AuthProvider';
import I18NProvider from './app/components/I18NProvider';
import { store } from './app/store';
import { theme } from './app/theme';
import * as serviceWorker from './serviceWorker';

function GrowTransition(props: TransitionProps) {
  return <Grow { ...props } />;
}

render(
  <React.StrictMode>
    <Provider store={ store }>
      <ThemeProvider theme={ theme }>
        <CssBaseline />
        <SnackbarProvider maxSnack={ 3 } TransitionComponent={ GrowTransition }
          anchorOrigin={ { horizontal: 'right', vertical: 'top' } }>
          <BrowserRouter>
            <AppFireProvider>
              <AuthProvider>
                <I18NProvider>
                  <Suspense fallback={ <div>Loading</div> }>
                    <App />
                  </Suspense>
                </I18NProvider>
              </AuthProvider>
            </AppFireProvider>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
