import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl-number-format/dist/index.js';

import { I18NContext } from '.';

export default function LocaleConfigUpdater() {
  const { currentLang } = useContext(I18NContext);
  const { setConfig } = useIntl();

  useEffect(() => {
    console.log({ currentLang });
    if (currentLang === 'en') return setConfig(cfg => ({ ...cfg, locale: 'en-US' }));
    setConfig(cfg => ({ ...cfg, locale: 'de-DE' }));
  }, [currentLang]);

  return null;
}
