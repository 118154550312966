import { FormControl, makeStyles, MenuItem, Select } from '@material-ui/core';
import i18next from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import BaseAppDialog from '../BaseAppDialog';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
}));

interface Props {
  dialogOpen: boolean,
  onClose: () => void
}

export default function LanguageSelectionDialog({ dialogOpen, onClose }: Props) {
  const classes = useStyles();
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);
  const { t } = useTranslation();

  const handleOk = () => {
    if (currentLanguage !== i18next.language) {
      i18next.changeLanguage(currentLanguage);
    }
    onClose();
  };

  return (
    <BaseAppDialog
      title={ t('general.dialog_title_language') }
      open={ dialogOpen }
      onDismiss={ onClose }
      onSubmit={ handleOk }
    >
      <form className={ classes.container }>
        <FormControl className={ classes.formControl }>
          <Select
            value={ currentLanguage }
            onChange={ (event) => setCurrentLanguage(event.target.value as string) }>
            <MenuItem value={ 'en' }>English</MenuItem>
            <MenuItem value={ 'de' }>Deutsch</MenuItem>
          </Select>
        </FormControl>
      </form>
    </BaseAppDialog>
  );
}
