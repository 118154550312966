import { collection, Firestore } from 'firebase/firestore';
import { useMemo } from 'react';

import useConverter from './useConverter';

export default function useCollectionRef<T>(firestore: Firestore, path: string, ...pathSegments: string[]) {
  const converter = useConverter<T>();
  const ref = useMemo(
    () => collection(firestore, path, ...pathSegments).withConverter(converter),
    [firestore, path, pathSegments],
  );
  return ref;
}
