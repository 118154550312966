import { cloneDeep } from 'lodash';
import { useCallback, useMemo } from 'react';
import { v4 } from 'uuid';

import { useAuthContext } from '../components/AuthProvider/hooks';
import { Address } from '../types';

export default function useAddresses() {
  const { updateProfile, profile } = useAuthContext();

  const addEditAddress = useCallback((address: Omit<Address, 'id'> & { id?: Address['id'] }) => {
    if (!updateProfile) return;

    const addressList = cloneDeep(profile?.addressList || []);
    const index = addressList.findIndex(({ id }) => id === address?.id);

    if (!address.id) address.id = v4();

    if (index !== -1) addressList[index] = address as Address;
    else addressList.push({ id: v4(), ...address });

    updateProfile({ addressList });
  }, [updateProfile]);

  const deleteAddress = useCallback((addressId: Address['id']) => {
    if (!updateProfile || !profile?.addressList) return;

    const addressList = profile.addressList.filter(({ id }) => id !== addressId);

    updateProfile({ addressList });
  }, [updateProfile]);

  return useMemo(() => {
    return {
      all: profile?.addressList || [],
      shipping: profile?.addressList?.filter(a => a.type === 'SHIPPING_ADDRESS') || [],
      billing: profile?.addressList?.filter(a => a.type === 'BILLING_ADDRESS') || [],
      deleteAddress, addEditAddress,
    };
  }, [profile, deleteAddress, addEditAddress]);
}
