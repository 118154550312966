import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { PropsWithChildren } from 'react';
import { AuthProvider, FirebaseAppProvider, FirestoreProvider, StorageProvider, useFirebaseApp } from 'reactfire';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

function InitFBProviders({ children }: PropsWithChildren<Record<never, never>>) {
  const app = useFirebaseApp();
  const firestoreInstance = getFirestore(app);
  const auth = getAuth(app);
  const storage = getStorage(app);

  return (
    <FirestoreProvider sdk={ firestoreInstance }>
      <AuthProvider sdk={ auth }>
        <StorageProvider sdk={ storage }>
          { children }
        </StorageProvider>
      </AuthProvider>
    </FirestoreProvider>
  );
}

export default function AppFireProvider({ children }: PropsWithChildren<Record<never, never>>) {
  return (
    <FirebaseAppProvider firebaseConfig={ firebaseConfig }>
      <InitFBProviders>
        { children }
      </InitFBProviders>
    </FirebaseAppProvider>
  );
}
