import { DocumentReference, getDoc } from 'firebase/firestore';
import { useContext, useState } from 'react';
import { useFirestore } from 'reactfire';
import { useAsyncEffect } from 'use-async-effect';

import { I18NContext } from '../components/I18NProvider';
import { docRef } from './useDocRef';

export default function useLocaleDocRef<T = unknown>(basePath?: string) {
  const db = useFirestore();
  const { currentLocale } = useContext(I18NContext);
  const [ref, setRef] = useState<DocumentReference<T>>();

  useAsyncEffect(async (isMounted) => {
    if (!basePath) return setRef(undefined);

    let ref = docRef<T>(db, `${ basePath }/locales/${ currentLocale }`);
    let locale = await getDoc(ref);

    if (!locale.exists() && currentLocale !== 'de-DE') {
      ref = docRef<T>(db, `${ basePath }/locales/de-DE`);
      locale = await getDoc(ref);
    }

    if (!locale.exists()) {
      console.log(`no translation for ${ basePath }`);
      return setRef(undefined);
    }

    if (isMounted()) setRef(ref);
  }, [basePath, currentLocale]);

  return ref;
}
