import { Card, CardContent, CardMedia, makeStyles } from '@material-ui/core';

import useUrl from '../../../app/hooks/useUrl';
import { HomeData } from '..';

const useStyles = makeStyles(theme => ({
  card: {
    flex: '1 1 30%',
    margin: theme.spacing(2, 1),
    minWidth: '130px',
    textAlign: 'center',
    borderRadius: theme.spacing(2),
    fontWeight: 'bold',
  },
  image: {
    objectFit: 'contain',
    padding: theme.spacing(3, 4),
    height: 'auto',
  },
}));

export default function WYGCard({ title, image }: HomeData['wyg']['wyg'][0]) {
  const classes = useStyles();
  const url = useUrl(image);

  return (
    <Card className={ classes.card }>
      <CardMedia component="img" className={ classes.image } alt="" image={ url } />
      <CardContent>
        { title }
      </CardContent>
    </Card>
  );
}
