import { Container } from '@material-ui/core';

import usePageTitle from '../../app/hooks/usePageTitle';
import Header from './Header';
import RegisterXmas from './RegisterXmas';
import useXmaspageData from './useXmaspageData';
import WhatYouGet from './WhatYouGet';

export default function XmasPage() {
  usePageTitle('xmas.page_title');

  const { loading, title, subtitle, wygTitle, wygTiles, registerTitle, registerContent, registerSpeechBubble } = useXmaspageData();
  if (loading) return <div>loading...</div>;

  return (
    <Container>
      <Header title={ title } subtitle={ subtitle } />
      <RegisterXmas registerTitle={ registerTitle } registerContent={ registerContent } registerSpeechBubble={ registerSpeechBubble } />
      <WhatYouGet wygTitle={ wygTitle } wygTiles={ wygTiles } />
    </Container>
  );
}
