import { Button, Grid, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAddresses from '../../../app/hooks/useAddresses';
import { Address } from '../../../app/types';
import GridItemCard from '../GridItemCard';
import AddressCard from './AddressCard';
import AddressModal from './AddressModal';

const useStyles = makeStyles(() => ({
  button: {
    height: '100%',
  },
}));

export default function ManageAddresses() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { billing, shipping } = useAddresses();
  const [modalOpen, setModalOpen] = useState<Address['type']>();

  return (
    <GridItemCard title={ t('profile.my_addresses_title') }>
      <Grid container spacing={ 2 }>
        { !shipping.length && (
          <Grid item sm={ 4 } xs={ 12 }>
            <Button variant="contained" color="primary" className={ classes.button } onClick={ () => setModalOpen('SHIPPING_ADDRESS') }>
              { t('profile.add_shipping_address_button') }
            </Button>
          </Grid>
        ) }
        { shipping?.map((address, i) => (
          <Grid key={ i } container item sm={ 4 } xs={ 12 }>
            <AddressCard address={ address } />
          </Grid>
        )) }
        { !billing.length && (
          <Grid item sm={ 4 } xs={ 12 }>
            <Button variant="outlined" className={ classes.button } onClick={ () => setModalOpen('BILLING_ADDRESS') }>
              { t('profile.add_billing_address_button') }
            </Button>
          </Grid>
        ) }
        { billing?.map((address, i) => (
          <Grid key={ i } container item sm={ 4 } xs={ 12 }>
            <AddressCard address={ address } />
          </Grid>
        )) }
      </Grid>
      <AddressModal open={ !!modalOpen } type={ modalOpen || 'SHIPPING_ADDRESS' } onClose={ () => setModalOpen(undefined) }/>
    </GridItemCard>
  );
}
