import { Box, Container, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';

import { HomeData } from '..';
import SwapboxCard from './SwapboxCard';

const useStyles = makeStyles({
  title: {
    fontSize: '50px',
    textAlign: 'center',
  },
  subtitle: {
    fontSize: '28px',
    textAlign: 'center',
  },
});

export default function SwapboxSection({ title, subtitle, swapBoxes }: HomeData['swapBoxData']) {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Container maxWidth="md">
      <Box mb={ isSmall ? 4 : 8 }>
        <Typography className={ classes.title } variant="h4">{ title }</Typography>
        <Typography className={ classes.subtitle } variant="h5">{ subtitle }</Typography>
      </Box>
      <Box display="flex" flexDirection={ isSmall ? 'column' : 'row' } justifyContent="space-between">
        { swapBoxes.map((swapbox, i) => <SwapboxCard
          key={ i }
          redirectUrl={ i === 0 ? '/gift-subscribe' : '/subscribe' } { ...swapbox } />) }
      </Box>
    </Container>
  );
}
