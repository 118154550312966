import { Button, makeStyles } from '@material-ui/core';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'reactfire';

import googleNormal from './g-logo.svg';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '20px',
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
  },
  startIcon: {
    marginRight: '24px',
  },
}));

export default function GoogleSignIn() {
  const auth = useAuth();
  const { t } = useTranslation();
  const classes = useStyles();

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    await signInWithPopup(auth, provider);
  };
  return (<Button
    variant="outlined"
    classes={ classes }
    color="primary"
    size="large"
    onClick={ signInWithGoogle }
    startIcon={ <img src={ googleNormal } /> }
  >
    { t('login.button_loginWithGoogle') }
  </Button>);
}
