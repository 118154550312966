import { CardMedia, CardMediaProps, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    '&::after': {
      content: '""',
      display: 'block',
      paddingBottom: '100%',
      /* The padding depends on the width, not on the height, so with a padding-bottom of 100% you will get a square */
    },
    [theme.breakpoints.down(500)]: {
      maxHeight: '150px',
    },
  },
  media: {
    position: 'absolute', /* Take your picture out of the flow */
    top: 0,
    bottom: 0,
    left: 0,
    right: 0, /* Make the picture taking the size of it's parent */
    width: '100%', /* This if for the object-fit */
    height: '100%', /* This if for the object-fit */
    objectFit: 'cover', /* Equivalent of the background-size: cover; of a background-image */
    objectPosition: 'center',
  },
}));

export default function CardImage({ className, ...props }: CardMediaProps) {
  const classes = useStyles();

  return (
    <div className={ className }>
      <div className={ classes.root }>
        <CardMedia
          className={ classes.media }
          title="Swap image"
          { ...props }
          { ...{ component: 'img' } }
        />
      </div>
    </div>
  );
}
