import './App.css';

import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useSigninCheck } from 'reactfire';

import FAQPage from '../features/FAQPage';
import GiftSubscribePage from '../features/GiftSubscriptionPage';
import HomePage from '../features/HomePage';
import LoginPage from '../features/LoginPage';
import MarkdownPage from '../features/MarkdownPage';
import ProfilePage from '../features/ProfilePage';
import SubscribePage from '../features/SubscribePage';
import SwapboxPage from '../features/SwapboxPage';
import XmasPage from '../features/XmasPage';
import PrivacyMd from './assets/privacy.md';
import TermsMd from './assets/terms.md';
import { useAuthContext } from './components/AuthProvider/hooks';
import CookieConsentBar from './components/CookieConsentBar';
import Layout from './components/Layout';
import ProtectedRoute from './components/ProtectedRoute';
import { BackState } from './types';

export default function App() {
  const { status } = useSigninCheck({ });
  // for checking different claims seperately, we can use multiple useSigninChecks in the future
  const loc = useLocation<BackState>();
  const { isAuthenticated } = useAuthContext();

  if (status === 'loading') {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <Switch>
        { /* default home */ }
        <Route exact path="/">
          <HomePage />
        </Route>

        <Route exact path="/xmas">
          <XmasPage />
        </Route>

        <Route exact path="/login">
          { !isAuthenticated ? <LoginPage /> : <Redirect to={ loc.state?.page || '/' } /> }
        </Route>

        <Route exact path="/about">
          <MarkdownPage pageTitle="about.page_title" mode="cms" content="about-page" />
        </Route>

        <Route exact path="/faq">
          <FAQPage />
        </Route>

        <Route exact path="/terms">
          <MarkdownPage pageTitle="terms.page_title" mode="file" content={ TermsMd } />
        </Route>

        <Route exact path="/privacy">
          <MarkdownPage pageTitle="privacy.page_title" mode="file" content={ PrivacyMd } />
        </Route>

        <Route exact path="/subscribe">
          <SubscribePage />
        </Route>

        <Route exact path="/gift-subscribe">
          <GiftSubscribePage />
        </Route>

        <ProtectedRoute exact path="/profile" isAuthorized={ isAuthenticated }>
          <ProfilePage />
        </ProtectedRoute>

        <ProtectedRoute exact path="/swapbox" isAuthorized={ isAuthenticated }>
          <SwapboxPage />
        </ProtectedRoute>

        { /* redirect to home for not found */ }
        <Redirect from="*" to="/" />
      </Switch>
      <CookieConsentBar />
    </Layout>
  );
}
