import { makeStyles } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { memo } from 'react';

const useStyles = makeStyles({
  expandMoreIcon: {
    border: '1px solid',
    width: '36px',
    height: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
  },
});

function ExpandMoreIcon() {
  const classes = useStyles();

  return (
    <div className={ classes.expandMoreIcon }>
      <ExpandMore style={ { color:'black' } } />
    </div>
  );
}

export default memo(ExpandMoreIcon);
