import {
  Box, createStyles, FormControl, Grid, InputLabel,
  ListItemText, makeStyles, MenuItem, Select, Theme,
} from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useSwaps from '../../app/hooks/useSwaps';
import { JewelryTypesOptions, MySwapDraft, Swap } from '../../app/types';
import { enumKeys } from '../../app/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: '90%',
    },
  }),
);

interface Props {
  onUpdate: (value: MySwapDraft) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const menuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function StepOne({ onUpdate }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [swap, setSwap] = useState<Swap>();
  const [jewelryType, setJewelryType] = useState<string>('');
  const { swaps } = useSwaps();

  const getOptionText = useCallback((key: string) => t(`select_jewelry_types.option_${ key.toLowerCase() }`), []);

  useEffect(() => {
    if (!swap || !jewelryType) return;
    onUpdate({ swap, jewelryType });
  }, [swap, jewelryType]);

  return (
    <Box>
      <Grid container spacing={ 1 }>
        <Grid item md={ 6 } sm={ 12 } xs={ 12 }>
          <FormControl className={ classes.formControl }>
            <InputLabel>{ t('select_swap.title') }</InputLabel>
            <Select
              value={ swap?.title || '' }
              onChange={ event => setSwap(swaps.find(({ title }) => event.target.value === title)) }
              MenuProps={ menuProps }>
              { swaps?.map(({ title }) => (
                <MenuItem key={ title } value={ title }>
                  <ListItemText primary={ title }/>
                </MenuItem>
              )) }
            </Select>
          </FormControl>
          <FormControl className={ classes.formControl }>
            <InputLabel>{ t('select_jewelry_types.title') }</InputLabel>
            <Select
              value={ jewelryType }
              onChange={ (event) => setJewelryType(event.target.value as string) }
              MenuProps={ menuProps }>
              { [...enumKeys(JewelryTypesOptions)].map((key: string) => (
                <MenuItem key={ key } value={ key }>
                  <ListItemText primary={ getOptionText(key || 'EMPTY') }/>
                </MenuItem>
              )) }
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
}
