import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { PropsWithChildren, ReactNode } from 'react';

import Footer from './Footer';
import Navbar from './Navbar';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100vh',
    background: theme.palette.background.default,
  },
  content: {
    flexGrow: 1,
    padding: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  toolbarSpacer: theme.mixins.toolbar,
}));

export default function Layout({ children }: PropsWithChildren<ReactNode>) {
  const classes = useStyles();

  return (
    <div className={ classes.container }>
      { /* navbar */ }
      <Navbar />

      { /* main content */ }
      <main
        className={ clsx(classes.content, {
          [classes.contentShift]: open,
        }) }
      >
        <div className={ classes.toolbarSpacer } />
        { children }
      </main>

      { /* sticky footer */ }
      <Footer />
    </div>
  );
}
