
import { makeStyles } from '@material-ui/core';
import { memo } from 'react';

import image from './spacer.png';

const useStyles = makeStyles(theme => ({
  spacer: {
    backgroundPosition: 'center',
    backgroundImage: `url(${ image })`,
    backgroundSize: 'contain',
    height: theme.spacing(2),
    margin: theme.spacing(8, 0),

    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(4, 0),
    },
  },
}));

function Spacer() {
  const classes = useStyles();
  return (<div className={ classes.spacer }></div>);
}

export default memo(Spacer);
