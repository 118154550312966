import { Box, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

interface Props {
  title: string;
  large?: boolean;
  className?: string;
}

const useStyles = makeStyles({
  headline: {
    fontSize: 'clamp(2rem, 6vw, 4rem)',
    textAlign: 'center',
  },
  large: {
    fontSize: 'clamp(2rem, 6vw, 4rem)',
  },
});

export default function Headline({ title, large, className }: Props) {
  const classes = useStyles();

  return (
    <Box mb={ 4 } className={ className }>
      <Typography className={ clsx(classes.headline, { [classes.large]: large }) } variant="h4" color="primary">{ title }</Typography>
    </Box>
  );
}
